import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../../util/asyncComponent";

const Customers = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/customers`} />
      <Route
        path={`${match.url}/customers`}
        component={asyncComponent(() => import("./routes/customers/basic"))}
      />

      <Route
        path={`${match.url}/subscriptions`}
        component={asyncComponent(() => import("./routes/subscriptions"))}
      />
      <Route
        path={`${match.url}/logs`}
        component={asyncComponent(() => import("./routes/logs"))}
      />
      <Route
        path={`${match.url}/setup`}
        component={asyncComponent(() => import("./routes/setup/basic"))}
      />
     

      <Route
        component={asyncComponent(() =>
          import("../../../app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Customers;
