import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

class DiscountCouponsHeader extends Component {

  render() {
    return (
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
        <h2 className="title mb-3 mb-sm-0">{this.props.title}</h2>
        {
          this.props.buttonVisible
            ?
              <Button
                variant="contained" color="primary"
                onClick={this.props.onButtonClickHandler}
              >
                Create Coupon
              </Button>
            :
              <Button
                variant="contained"
                disabled
              >
                Create Coupon
              </Button>
        }
      </div>
    )
  }
};

export default DiscountCouponsHeader;

