import React, { Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header";
import Sidebar from "../containers/SideNav/index";
import Footer from "components/Footer";

//own
import Customers from "./routes/customers";

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import { getEmployer } from "actions/userActions";
import ColorOption from "../containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../../util/asyncComponent";
import TopNav from "components/TopNav";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.props.getEmployer();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.getEmployerStatus === "done") {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {this.state.loading ? (
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
        ) : (
          <Fragment>
            <Sidebar />
            <div className="app-main-container">
              <div
                className={`app-header ${
                  navigationStyle === HORIZONTAL_NAVIGATION
                    ? "app-header-horizontal"
                    : ""
                }`}
              >
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === ABOVE_THE_HEADER && (
                    <TopNav styleName="app-top-header" />
                  )}
                <Header />
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
              </div>

              <main className="app-main-content-wrapper">
                <div className="app-main-content">
                  <Switch>
                    {/* own code */}
                    <Route
                      path={`${match.url}/dashboard`}
                      component={Customers}
                    />

                    <Route
                      component={asyncComponent(() =>
                        import("../app/routes/extraPages/routes/404")
                      )}
                    />
                  </Switch>
                </div>
                <Footer />
              </main>
            </div>
            <ColorOption />
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ settings, userReducer }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const {
    saveEmployerStatus,
    loading,
    employer,
    getEmployerStatus
  } = userReducer;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    saveEmployerStatus,
    loading,
    employer,
    getEmployerStatus
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { getEmployer }
  )(App)
);
