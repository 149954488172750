import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import labels from "admin/app/routes/shopCards/data/labels";

function formatBalance(balance) {
  let b = balance.sort();
  let bal = b.map((c, i) => {
    if (i !== balance.length - 1) return "$" + c + ", ";
    else return "$" + c;
  });

  return bal;
}

const MailListItem = ({ mail, onMailSelect, onMailChecked, onStartSelect }) => {
  return (
    <div className="module-list-item mail-cell">
      <Checkbox
        color="primary"
        checked={mail.selected}
        onClick={event => {
          event.stopPropagation();
          onMailChecked(mail);
        }}
        value="SelectMail"
      />

      <div className="mail-user-info">
        {mail.merchant_icon_image_url_hd === "" ? (
          <div
            className="bg-blue avatar rounded-circle size-40 text-white text-center"
            style={{ fontSize: 16 }}
          >
            {" "}
            {mail.merchant_name.charAt(0).toUpperCase()}
          </div>
        ) : (
          <img
            className="rounded-circle avatar size-40"
            alt={mail.merchant_name.charAt(0).toUpperCase()}
            src={mail.merchant_icon_image_url_hd}
          />
        )}
      </div>

      <div
        className="module-list-info"
        onClick={event => {
          event.stopPropagation();
          onMailChecked(mail);
        }}
      >
        <div className="module-list-content">
          <div className="mail-user-info">
            <span className="sender-name text-dark">{mail.merchant_name}</span>

            {/* <span className="toolbar-separator" /> */}

            {/* <span
              className="d-inline-block text-truncate text-dark"
              style={{ maxWidth: "calc(100% - 220px)" }}
            >
              {mail.subject}
            </span> */}

            {/* {mail.hasAttachments && <i className="zmdi zmdi-attachment" />} */}

            <div className="time">
              <span
                style={{
                  marginTop: 5,
                  fontSize: 20,
                  fontWeight: "bold"
                }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: "#04bffe"
                  }}
                >
                  {mail.card_currency_code}
                  {"  "}
                </span>
                {mail.opening_balance}
              </span>
            </div>
          </div>

          <div
            className="message mb-2"
            dangerouslySetInnerHTML={{ __html: mail.long_description }}
          />
          <div className="labels">
            <div className={`badge text-white bg-light-blue darken-2`}>
              Denominations:{" "}
              {formatBalance(
                mail.shopCards.map((c, i) => {
                  return c.opening_balance;
                })
              )}
            </div>
            {/* {labels.map((label, index) => {
              return (
                mail.labels.includes(label.id) && (
                  <div
                    key={index}
                    className={`badge text-white bg-purple lighten-3`}
                  >
                    {label.title}
                  </div>
                )
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailListItem;
