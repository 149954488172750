import { db } from "../firebase/firebase";
import toast from "toastr";
import Endpoint from "../Endpoint";
import { endPoint, apiKey } from "../config";
import axios from "axios";
export const ADD_NEW_DOCUMENTS = "ADD_NEW_DOCUMENTS";
export const ADD_NEW_DOCUMENTS_ERR = "ADD_NEW_DOCUMENTS_ERR";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_ERR = "GET_DOCUMENTS_ERR";
export const GET_AUTOPOPULATE = "GET_AUTOPOPULATE";
export const GET_AUTOPOPULATE_ERR = "GET_AUTOPOPULATE_ERR";
export const GET_UNVERIFIEDDOCUMENTS = "GET_UNVERIFIEDDOCUMENTS";
export const GET_UNVERIFIEDDOCUMENTS_ERR = "GET_UNVERIFIEDDOCUMENTS_ERR";
export const GET_TEMPLATESCHEMA = "GET_TEMPLATESCHEMA";
export const GET_TEMPLATESCHEMA_ERR = "GET_TEMPLATESCHEMA_ERR";
export const GET_SINGLEEMPLOYEE = "GET_SINGLEEMPLOYEE";
export const GET_SINGLEEMPLOYEER = "GET_SINGLEEMPLOYEER";
export const ADD_NEW_UNVERIFIED_DOCUMENTS = "ADD_NEW_UNVERIFIED_DOCUMENTS";
export const GET_UNVERIFIED_STATE_DOC = "GET_UNVERIFIED_STATE_DOC";
export const ADD_VERIFIED_STATE_DOC = "ADD_VERIFIED_STATE_DOC";
export const GET_STATE_DOCS = "GET_STATE_DOCS";

/////////////////// JOTFORM //////////////////////////
export const GET_JOT_FORM = "GET_JOT_FORM";
export const ADD_VERIFIED = "ADD_VERIFIED";

/////////////////////////////////////////////////////

export function addNewDocuments(data, docType, cb) {
  console.log("D", data);
  return async (dispatch) => {
    try {
      const res = await addTemplate(data);
      // console.log("res from server", res.data);
      if (res.data.id) {
        let datatodb = {
          orignalFileName: res.data.orignalFileName,
          doc_name: res.data.name,
          template_id: res.data.id,
          status: "unverified",
          docType,
          createdAt: `${new Date()}`,
        };
        // console.log("for db", datatodb);

        let addRes;
        if (docType === "System Document") {
          addRes = await addSystemDoc(datatodb);

          toast.success("Succefully add System documents");
        } else {
          addRes = await addStateDoc(datatodb);
        }
        // console.log("addRes", addRes);

        dispatch({
          type: ADD_NEW_DOCUMENTS,
        });
        cb();
      } else {
        // console.log("id not found");

        dispatch({
          type: ADD_NEW_DOCUMENTS_ERR,
        });
        toast.error("Error Has been Occoured!");
      }
    } catch (error) {
      // console.log("error", error);

      dispatch({
        type: ADD_NEW_DOCUMENTS_ERR,
      });
      toast.error(error.message);
    }
  };
}

// add new documents supporting functions========================================

const addTemplate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${Endpoint}/formapi/createTemplate`, data, {
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
      .then(resolve)
      .catch(reject);
  });

const addSystemDoc = (data) =>
  new Promise((resolve, reject) => {
    db.collection("systemdocuments").add(data).then(resolve).catch(reject);
  });
const addStateDoc = (data) =>
  new Promise((resolve, reject) => {
    db.collection("stateDocuments").add(data).then(resolve).catch(reject);
  });

// add new documents supporting functions========================================end

// Get all task
export function getSystemDocuments() {
  return (dispatch) => {
    db.collection("systemdocuments")
      // .where("status", "==", "verified")
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            let data = doc.data();
            let doc_id = doc.id;
            let final = {
              ...data,
              doc_id,
            };

            datatoStore.push(final);
          });

          dispatch({
            type: GET_DOCUMENTS,
            payload: datatoStore,
          });
        },
        function (err) {
          dispatch({
            type: GET_DOCUMENTS_ERR,
          });
        }
      );
  };
}
export function getStateDocs(cb) {
  return (dispatch) => {
    db.collection("stateDocuments")
      .where("status", "==", "verified")
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            let data = doc.data();
            let doc_id = doc.id;
            let final = {
              ...data,
              doc_id,
            };

            datatoStore.push(final);
          });

          dispatch({
            type: GET_STATE_DOCS,
            payload: datatoStore,
          });
          cb("success");
        },
        function (err) {
          cb("error", err);
        }
      );
  };
}

export function getUnverifiedDocuments() {
  return (dispatch) => {
    db.collection("systemdocuments")
      // .select('documnentName')
      .where("status", "==", "unverified")
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            let data = doc.data();
            let doc_id = doc.id;
            let final = {
              ...data,
              doc_id,
            };

            datatoStore.push(final);
          });
          dispatch({
            type: GET_UNVERIFIEDDOCUMENTS,
            payload: datatoStore,
          });
        },
        function (err) {
          dispatch({
            type: GET_UNVERIFIEDDOCUMENTS_ERR,
          });
        }
      );
  };
}
export function getUnverifiedStateDocuments() {
  return (dispatch) => {
    db.collection("stateDocuments")
      .where("status", "==", "unverified")
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function (doc) {
            let data = doc.data();
            let doc_id = doc.id;
            let final = {
              ...data,
              doc_id,
            };

            datatoStore.push(final);
          });
          // console.log("state documents here", datatoStore);

          dispatch({
            type: GET_UNVERIFIED_STATE_DOC,
            payload: datatoStore,
          });
        },
        function (err) {
          toast.error(err.message);
        }
      );
  };
}

export function addVerifiedDocuments(data) {
  return (dispatch) => {
    db.collection("systemdocuments")
      .doc(data.doc_id)
      .update(data.record)
      .then(function (docRef) {
        toast.success("Successfully add verified System documents");
        dispatch({
          type: ADD_NEW_UNVERIFIED_DOCUMENTS,
        });
      })
      .catch(function (error) {
        toast.error("Error occured. Please try again later");
        dispatch({
          type: ADD_NEW_UNVERIFIED_DOCUMENTS,
        });
      });
  };
}
export function addVerifiedStateDoc(data) {
  return (dispatch) => {
    db.collection("stateDocuments")
      .doc(data.doc_id)
      .update(data.record)
      .then(function (docRef) {
        toast.success("Successfully add verified State documents");
        dispatch({
          type: ADD_VERIFIED_STATE_DOC,
        });
      })
      .catch(function (error) {
        toast.error("Error occurred. Please try again later");
      });
  };
}

export function deleteStateDoc(id) {
  return (dispatch) => {
    db.collection("stateDocuments")
      .doc(id)
      .delete()
      .then((docRef) => {
        toast.success("Successfully delete the document");
      })
      .catch((error) => {
        toast.error("Error occurred. Please try again later");
      });
  };
}
export function updateStateDoc(data) {
  // console.log("data in action", data);

  return (dispatch) => {
    db.collection("stateDocuments")
      .doc(data.doc_id)
      .update(data)
      .then(function (docRef) {
        toast.success("Successfully Update State Documents");
        // dispatch({
        //   type: ADD_VERIFIED_STATE_DOC
        // });
      })
      .catch(function (error) {
        toast.error("Error occurred. Please try again later");
      });
  };
}

export function getAutoPopulate() {
  return (dispatch) => {
    db.collection("autopopulate")
      // .select('documnentName')
      .onSnapshot(
        function (querySnapshot) {
          let datatoStore = {};
          querySnapshot.forEach(function (doc) {
            const data = doc.data();
            datatoStore = data;
          });
          // console.log(" autopopulate", datatoStore);

          dispatch({
            type: GET_AUTOPOPULATE,
            payload: datatoStore,
          });
        },
        function (err) {
          dispatch({
            type: GET_AUTOPOPULATE_ERR,
          });
        }
      );
  };
}

export function getSingleEmployeeForTesting() {
  return (dispatch) => {
    db.collection("users")
      .orderBy("createdAt", "desc") // Order documents by added_at field in
      .limit(1)
      .get()
      .then(function (prevSnapshot) {
        let datatoStore = {};
        prevSnapshot.forEach(function (doc) {
          const data = doc.data();
          datatoStore = data;
        });
        // console.log("single Employee", datatoStore);

        dispatch({
          type: GET_SINGLEEMPLOYEE,
          payload: datatoStore,
        });
      });
  };
}
export function getSingleEmployerForTesting() {
  return (dispatch) => {
    db.collection("users")
      .orderBy("createdAt", "desc") // Order documents by added_at field in
      .limit(1)
      .get()
      .then(function (prevSnapshot) {
        let datatoStore = {};
        prevSnapshot.forEach(function (doc) {
          const data = doc.data();
          datatoStore = data;
        });
        // console.log("single Employer", datatoStore);

        dispatch({
          type: GET_SINGLEEMPLOYEER,
          payload: datatoStore,
        });
      });
  };
}

export function getTemplateSchema(id) {
  // console.log("id get", id);

  return (dispatch) => {
    axios
      .post(`${Endpoint}/formapi/getTemplateSchema`, { id: id })
      .then((res) => {
        // console.log("res", res.data);

        if (res.data.properties) {
          let datatoStore = {
            ...res.data,
            template_id: id,
          };
          dispatch({
            type: GET_TEMPLATESCHEMA,
            payload: datatoStore,
          });
        } else {
          console.error("err", res.data);
          toast.error("Error Has been Occoured! Try again later");
          dispatch({
            type: GET_TEMPLATESCHEMA_ERR,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: GET_TEMPLATESCHEMA_ERR,
        });
        toast.error("Error Has been Occoured! Try agin later");
      });
  };
}
///////////////////////////////////////////////////

/////////////////////// JOTFORM /////////////////////////////////
export function getDocuments() {
  return (dispatch) => {
    axios
      .get(`${endPoint}/user/forms&apikey=${apiKey}`)
      .then((res) => {
        let unVerified = [];
        let documents = [];
        if (res.data.content.length !== 0) {
          for (let x of res.data.content) {
            if (x.status === "ENABLED") {
              documents.push(x);
            }
          }
          db.collection("systemdocuments")
            .where("generatedBy", "==", "jot-form")
            .get()
            .then(async (doc) => {
              if (doc.docs.length !== 0) {
                let firebaseData = [];
                doc.forEach((docRecord) => {
                  let data = docRecord.data();
                  firebaseData.push(data);
                });
                documents.forEach((jotDoc) => {
                  if (
                    firebaseData.findIndex(
                      (element) => jotDoc.id === element.id
                    ) === -1
                  ) {
                    unVerified.push(jotDoc);
                  }
                });
                dispatch({
                  type: GET_JOT_FORM,
                  payload: unVerified,
                });
              } else {
                dispatch({
                  type: GET_JOT_FORM,
                  payload: documents,
                });
              }
            });
        } else {
          dispatch({
            type: GET_JOT_FORM,
            payload: [],
          });
        }
      })
      .catch((err) => {
        toast.error("Error Has been Occoured! Try agin later");
      });
  };
}
//// Add varified docs
export function addVerifiedDoc(data) {
  return (dispatch) => {
    addSystemDoc(data)
      .then((res) => {
        toast.success("Varified successfully");
        dispatch({
          type: ADD_VERIFIED,
          payload: data,
        });
      })
      .catch((err) => console.log("ERR", err));
  };
}
//// Add docs
export function addNewDoc(data, cb) {
  return (dispatch) => {
    addSystemDoc(data)
      .then((res) => {
        toast.success("Varified successfully");
        dispatch({
          type: ADD_NEW_DOCUMENTS,
        });
        cb();
      })
      .catch((err) => toast.error("Something went wrong!"));
  };
}

// Delete varified doc
export function deleteSystemDoc(id) {
  return (dispatch) => {
    db.collection("systemdocuments")
      .doc(id)
      .delete()
      .then((docRef) => {
        toast.success("Successfully delete the document");
        dispatch({
          type: ADD_NEW_UNVERIFIED_DOCUMENTS,
        });
      })
      .catch((error) => {
        toast.error("Error occurred. Please try again later");
      });
  };
}
