import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import DateTimePicker from "../coupons/DateTimePicker";
import { connect } from "react-redux";
import toastr from 'toastr';
import CardBox from "components/CardBox";
import {
    createCoupon
} from "actions/couponActions";

class From extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            percentDiscount: "",
            validFrom: new Date(),
            validUpto: new Date(),
            createLoader: false
        }
    }
    
    handleFromDateChange = (date) => {
        this.setState({ validFrom: date });
    };
    
    handleUptoDateChange = (date) => {
        this.setState({ validUpto: date });
    };

    generateCouponCode = () => {
        let coupon = "";
        let possible = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for (var i = 0; i < 15; i++) {
            coupon += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return coupon;
    }

    createCoupon = () => {
        if(this.state.name == "") {
            toastr.error("Please enter coupon name");
        } else if(this.state.percentDiscount == "") {
            toastr.error("Please enter discount percentage");
        } else if(this.state.percentDiscount > 100) {
            toastr.error("Discount cannot be greater than 100%");
        } else if(this.state.validUpto < this.state.validFrom) {
            toastr.error("Invalid coupon expiry date");
        } else {
            this.setState({
                createLoader: true
            });
            this.props.createCoupon({
                name: this.state.name,
                percentDiscount: this.state.percentDiscount,
                code: this.generateCouponCode(),
                validFrom: new Date(this.state.validFrom),
                validUpto: new Date(this.state.validUpto)
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.createCouponStatus == "done") {
            this.setState({
                createLoader: false
            });
            this.props.hideForm();
        } else if(nextProps.createCouponStatus == "error") {
            this.setState({
                createLoader: false
            });
        }
    }

    handleDiscountChange = (event) => {
        this.setState({percentDiscount: event.target.value})
    }

    render() {
        return (
            <CardBox key={this.props.i} styleName="col-lg-12">
                <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: 30, marginLeft: 12 }}>
                    <img
                        style={{
                            alignSelf: "flex-end",
                            marginTop: -20,
                            marginRight: -20
                        }}
                        src={require('../../../../../assets/coupon.png')}
                    />
                    <div>
                        <h3>
                            <b>Coupon Name: </b>
                            <TextField
                                style={{marginTop: -7, marginLeft: 5}}
                                name="name"
                                type="text"
                                value={this.state.name}
                                onChange={(event)=>this.setState({name: event.target.value})}
                            />
                        </h3>
                    </div>
                    <div style={{marginTop: 5}}>
                        <h3>
                            <b>Percent Discount: </b>
                            <TextField
                                style={{marginTop: -7, marginLeft: 5}}
                                name="discount"
                                type="number"
                                value={this.state.percentDiscount}
                                onChange={(event)=>this.handleDiscountChange(event)}
                            />
                        </h3>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <CardBox key={this.props.i} styleName="col-lg-6">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                <h3>
                                    <i
                                    color="blue"
                                    className="zmdi zmdi-calendar-check zmdi-hc-fw zmdi-hc-lg"
                                    />
                                    Valid From
                                </h3>
                            </div>
                            <div>
                                <DateTimePicker
                                    handleDateChange={this.handleFromDateChange}
                                    date={this.state.validFrom}
                                />
                            </div>
                        </div>
                    </CardBox>
                    <CardBox key={this.props.i} styleName="col-lg-6">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div>
                                <h3>
                                    <i
                                        color="blue"
                                        className="zmdi zmdi-calendar-close zmdi-hc-fw zmdi-hc-lg"
                                    />
                                    Valid Upto
                                </h3>
                            </div>
                            <div>
                                <DateTimePicker
                                    handleDateChange={this.handleUptoDateChange}
                                    date={this.state.validUpto}
                                />
                            </div>
                        </div>
                    </CardBox>
                </div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            variant="contained" color="primary"
                            style={{marginRight: 30}}
                            onClick={()=>this.props.hideForm()}
                        >
                            Cancel
                        </Button>
                        {
                            this.state.createLoader
                            ?
                                <Button
                                    variant="contained" color="primary"
                                    style={{marginRight: 13}}
                                >
                                    loading...
                                </Button>
                            :
                                <Button
                                    variant="contained" color="primary"
                                    style={{marginRight: 13}}
                                    onClick={()=>this.createCoupon()}
                                >
                                    Create
                                </Button>
                        }
                    </div>
                </div>
            </CardBox>
        )
    }
}

const mapStateToProps = state => ({
    createCouponStatus: state.couponsReducer.createCouponStatus,
    loader: state.couponsReducer.loader
});
  
export default connect(
    mapStateToProps,
    {
        createCoupon
    }
)(From);