import React, { Component } from "react";
import Coupons from "./coupons";
import Form from "./creationForm";
import CardBox from "components/CardBox";
import DiscountCouponsHeader from "components/DiscountCouponsHeader";

class ExpansionPanel extends Component {
  state = {
    isFormVisible: false
  }
  
  onButtonClick = () => {
    this.setState({
      isFormVisible: true
    });
  }

  hideForm = () => {
    this.setState({
      isFormVisible: false
    });
  }

  render() {
    let { match } = this.props;  
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <DiscountCouponsHeader
          title="Coupons"
          buttonVisible={!this.state.isFormVisible}
          onButtonClickHandler={this.onButtonClick}
        />
        <div className="row mb-md-3">
          <CardBox styleName="col-lg-12" cardStyle="bg-transparent no-shadow p-0">
            {
              this.state.isFormVisible
                ?
                  <Form
                    hideForm={this.hideForm}
                  />
                :
                  <Coupons />
            }
          </CardBox>
        </div>
      </div>
    )};
};

export default ExpansionPanel;
