import { GET_NEW_EMPLOYEE } from "../../actions/authorizerAction/newEmployees";

var initialState = {
  newEmployees: []
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NEW_EMPLOYEE:
      return {
        ...state,
        newEmployees: payload
      };

    default:
      return state;
  }
}
