import {
    ADD_SHIFT,
    ADD_SHIFT_ERR,
    GET_SHIFT,
    GET_SHIFT_ERR,
    ASSIGN_SHIFT,
    ASSIGN_SHIFT_ERR,
    GET_ASS_SHIFT,
    GET_ASS_SHIFT_ERR,
    UPDATE_ASS_SHIFT,
    UPDATE_ASS_SHIFT_ERR
  } from "../../actions/reviewer_actions/shiftActions";
  
  const initialState = {
    allShifts: [],
    assignedShiftsEmployee: [],
    shiftAddStatus: "not done",
    loader: new Date(),
    getShiftStatus: "not done",
    editShift: {},
    editShiftStatus: "not done",
    deleteShiftStatus: "not done",
    shiftUpdateStatus: "not done",
    employeeContacts: [],
    getContactStatus: "not done",
    msgDeleteStatus: "not done",
    shiftAssignedStatus: 'not done',
    getAssignedShiftStatus: 'not done',
    updateAssignedShiftStatus: 'not done'
  };
  export default (state = initialState, action) => {
    switch (action.type) {
      
      //   add shift
      case ADD_SHIFT:
        return {
          ...state,
          shiftAddStatus: "done",
          getShiftStatus: 'not done',
          editShiftStatus: "not done",
          deleteShiftStatus: "not done",
          shiftUpdateStatus: "not done",
          getContactStatus: "not done",
          msgDeleteStatus: "not done",
          shiftAssignedStatus: 'not done',
          getAssignedShiftStatus: 'not done',
          updateAssignedShiftStatus: 'not done',
          loader: new Date()
        };
      case ADD_SHIFT_ERR:
        return {
          ...state,
          shiftAddStatus: "error",
          getShiftStatus: 'not done',
          editShiftStatus: "not done",
          deleteShiftStatus: "not done",
          shiftUpdateStatus: "not done",
          getContactStatus: "not done",
          msgDeleteStatus: "not done",
          shiftAssignedStatus: 'not done',
          getAssignedShiftStatus: 'not done',
          updateAssignedShiftStatus: 'not done',
          loader: new Date()
        };

        // Get shifts
        case GET_SHIFT:
          return {
            ...state,
            loader: new Date(),
            allShifts: action.payload,
            getShiftStatus: "done",
            shiftAddStatus: "not done",
            editShiftStatus: "not done",
            deleteShiftStatus: "not done",
            shiftUpdateStatus: "not done",
            getContactStatus: "not done",
            msgDeleteStatus: "not done",
            shiftAssignedStatus: 'not done',
            getAssignedShiftStatus: 'not done',
            updateAssignedShiftStatus: 'not done',
          };
        // Get shifts error
        case GET_SHIFT_ERR:
          return {
            ...state,
            loader: new Date(),
            getShiftStatus: "error",
            shiftAddStatus: "not done",
            editShiftStatus: "not done",
            deleteShiftStatus: "not done",
            shiftUpdateStatus: "not done",
            getContactStatus: "not done",
            msgDeleteStatus: "not done",
            shiftAssignedStatus: 'not done',
            getAssignedShiftStatus: 'not done',
            updateAssignedShiftStatus: 'not done',
          };
      
          //   asign shift
          case ASSIGN_SHIFT:
            return {
              ...state,
              shiftAssignedStatus: "done",
              getShiftStatus: 'not done',
              shiftAddStatus: "not done",
              editShiftStatus: "not done",
              deleteShiftStatus: "not done",
              shiftUpdateStatus: "not done",
              getContactStatus: "not done",
              msgDeleteStatus: "not done",
              getAssignedShiftStatus: 'not done',
              updateAssignedShiftStatus: 'not done',
              loader: new Date()
            };
          case ASSIGN_SHIFT_ERR:
            return {
              ...state,
              shiftAssignedStatus: "error",
              getShiftStatus: 'not done',
              editShiftStatus: "not done",
              shiftAddStatus: "not done",
              deleteShiftStatus: "not done",
              shiftUpdateStatus: "not done",
              getContactStatus: "not done",
              msgDeleteStatus: "not done",
              getAssignedShiftStatus: 'not done',
              updateAssignedShiftStatus: 'not done',
              loader: new Date()
            };
      
            //   get assigned shifts
            case GET_ASS_SHIFT:
              return {
                ...state,
                assignedShiftsEmployee: action.payload,
                getAssignedShiftStatus: "done",
                getShiftStatus: 'not done',
                editShiftStatus: "not done",
                shiftAddStatus: "not done",
                deleteShiftStatus: "not done",
                shiftUpdateStatus: "not done",
                getContactStatus: "not done",
                msgDeleteStatus: "not done",
                shiftAssignedStatus: 'not done',
                updateAssignedShiftStatus: 'not done',
                loader: new Date()
              };
            case GET_ASS_SHIFT_ERR:
              return {
                ...state,
                getAssignedShiftStatus: "error",
                getShiftStatus: 'not done',
                shiftAddStatus: "not done",
                editShiftStatus: "not done",
                deleteShiftStatus: "not done",
                shiftUpdateStatus: "not done",
                getContactStatus: "not done",
                msgDeleteStatus: "not done",
                shiftAssignedStatus: 'not done',
                updateAssignedShiftStatus: 'not done',
                loader: new Date()
              };
      
              //   update assigned shift
              case UPDATE_ASS_SHIFT: {
                let shifts = state.assignedShiftsEmployee.map( shift => {
                  if(shift.id === action.payload.id) {
                    return action.payload;
                  } else {
                    return shift;
                  }
                })
                
                return {
                  ...state,
                  assignedShiftsEmployee: shifts,
                  updateAssignedShiftStatus: "done",
                  getShiftStatus: 'not done',
                  shiftAddStatus: "not done",
                  editShiftStatus: "not done",
                  deleteShiftStatus: "not done",
                  shiftUpdateStatus: "not done",
                  getContactStatus: "not done",
                  msgDeleteStatus: "not done",
                  shiftAssignedStatus: 'not done',
                  getAssignedShiftStatus: 'not done',
                  loader: new Date()
                };
              }
              case UPDATE_ASS_SHIFT_ERR:
                return {
                  ...state,
                  updateAssignedShiftStatus: "error",
                  getShiftStatus: 'not done',
                  shiftAddStatus: "not done",
                  editShiftStatus: "not done",
                  deleteShiftStatus: "not done",
                  shiftUpdateStatus: "not done",
                  getContactStatus: "not done",
                  msgDeleteStatus: "not done",
                  shiftAssignedStatus: 'not done',
                  getAssignedShiftStatus: 'not done',
                  loader: new Date()
                };
  
      default:
        return state;
    }
  };
  