import {
    CREATE_REVIEWER,
    CREATE_REVIEWER_ERR,
  } from "../../actions/createReviewerAction";
  
  const initialState = {
    allReviewers: [],
    reviewerAddStatus: "not done",
    loader: new Date(),
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      
      //   create reviewer
      case CREATE_REVIEWER: {
        let reviewers = state.allReviewers;
        reviewers.push(action.payload);

        return {
          ...state,
          allReviewers: reviewers,
          reviewerAddStatus: "done",
          loader: new Date()
        };
    }

      case CREATE_REVIEWER_ERR:
        return {
          ...state,
          reviewerAddStatus: "error",
          loader: new Date()
        };

      default:
        return state;
    }
  };
  