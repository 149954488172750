import {
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    List,
    Menu,
    MenuItem,
} from '@material-ui/core';
import React from 'react';
import logo from '../../assets/logo.png';
import styles from './Navbar.module.scss';
//   import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../WindowDimension';
import MenuIcon from '@material-ui/icons/Menu';
export default function Navbar() {
    const [open, setOpen] = React.useState(false);
    const { width, height } = useWindowDimensions();
    const [login, setLogin] = React.useState(false);
    // let navigate = useNavigate();
    const navigateLink = (link) => {
        window.location.href = link;
        handleClose();
    };
    const isOpen = () => {
        setOpen(true);
    };
    const isClose = () => {
        setOpen(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const list = (anchor) => (
        <Box
            sx={{ width: 280 }}
            role="presentation"
            onClick={isClose}
            onKeyDown={isClose}
        >
            <div className={styles.logomobilediv}>
                <img src={logo} className={styles.logomobile} alt="logo" />
            </div>
            <List sx={{ marginTop: 7 }}>
                <ul
                    style={{ listStyleType: 'none', paddingLeft: 15, paddingRight: 15 }}
                >
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/">Home</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/#features">features</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/#pricings">pricing</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/#faq">faqs</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/#blog">blog</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <a href="https://helpara.com/#footer">contact</a>
                    </li>
                    <li className={styles.linkMobile}>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigateLink('/createAccount/employer');
                            }}
                        >
                            Employer Signup
                        </span>
                    </li>
                    <li className={styles.linkMobile}>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                navigateLink('/createAccount/employee');
                            }}
                        >
                            Employee Signup
                        </span>
                    </li>
                </ul>
            </List>
        </Box>
    );

    return (
        <div
            style={{
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingLeft: '80px',
                paddingRight: '80px',
            }}
        >
            <Grid container>
                <Grid item xl={2} lg={2} md={width < 1200 ? 6 : 3} sm={6} xs={6}>
                    <a href="https://helpara.com/">
                        <img src={logo} alt="logo" className={styles.navLogo} />
                    </a>
                </Grid>
                {width < 1200 ? (
                    ''
                ) : (
                    <Grid item xl={7} lg={7} md={6} sm={0} xs={0}>
                        <ul
                            className={styles.navLink}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                listStyleType: 'none',
                            }}
                        >
                            <li>
                                <a href="https://helpara.com/">Home</a>
                            </li>
                            <li>
                                <a href="https://helpara.com/#features">Features</a>
                            </li>
                            <li>
                                <a href="https://helpara.com/#pricings">Pricing</a>
                            </li>
                            <li>
                                <a href="https://helpara.com/#faq">FAQ</a>
                            </li>
                            <li>
                                <a href="https://helpara.com/#blog">Blog</a>
                            </li>
                            <li>
                                <a href="https://helpara.com/#footer">Contact</a>
                            </li>
                        </ul>
                    </Grid>
                )}

                <Grid item xl={3} lg={3} md={width < 1200 ? 6 : 3} sm={6} xs={6}>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        {width < 762 ? null : (
                            <>
                                <button
                                    className={styles.buttonNavbar}
                                    onClick={() => navigateLink('/')}
                                >
                                    Login
                                </button>
                                <div>
                                    <li style={{ listStyleType: 'none' }}>
                                        <span
                                            className={styles.login_btn}
                                            href="#"
                                            onClick={() => setLogin((value) => !value)}
                                        >
                                            Sign Up
                                        </span>
                                        {login && (
                                            <ul className={styles.sub_menu}>
                                                <li>
                                                    <span
                                                        onClick={() => {
                                                            navigateLink('/createAccount/employer');
                                                        }}
                                                    >
                                                        Employer
                                                    </span>
                                                </li>
                                                <li>
                                                    <span
                                                        onClick={() => {
                                                            navigateLink('/createAccount/employee');
                                                        }}
                                                    >
                                                        Employee
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                </div>
                            </>
                        )}
                        {width < 1200 ? (
                            <>
                                <IconButton
                                    onClick={isOpen}
                                    style={{ marginTop: width < 762 ? 20 : 15, marginLeft: 40 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <div>
                                    <Drawer anchor={'left'} open={open} onClose={isClose}>
                                        {list('left')}
                                    </Drawer>
                                </div>
                            </>
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
