import React from "react";
import { connect } from "react-redux";
import { startLogout } from "actions/Auth";
import IntlMessages from "util/IntlMessages";

class UserInfoPopup extends React.Component {
  render() {
    return (
      <div>
        <div className="user-profile">
    
          <div className="user-detail ml-2">
            <h4 className="user-name mb-0">{this.props.user.name}</h4>
            <small>Authorizer</small>
          </div>
        </div>

        <span
          className="jr-link dropdown-item text-muted"
          onClick={() => {
            console.log("Try to logout");
            this.props.startLogout();
          }}
        >
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1" />
          Logout1
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // const {locale} = settings;
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps, { startLogout })(UserInfoPopup);
