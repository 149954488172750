import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import HomeIcon from '@material-ui/icons/Home';
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  reviewerSignin,
  userTwitterSignIn
} from "../../actions/Auth";
import { addLog } from "../../actions/reviewer_actions/logActions";
import Navbar from "components/navbar/Navbar";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      loader: false
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      loader: false
    });
    if (nextProps.userStatus && nextProps.isLoading == "move" && nextProps.userType === "reviewer") {
      this.props.addLog({
        reviewer: nextProps.user.name,
        timestamp: new Date(),
        loggedIn: true
      });
      this.props.history.push("/reviewer/dashboard/employers");
    } else if (nextProps.userStatus && nextProps.isLoading == "move" && nextProps.userType === "authorizer") {
      this.props.history.push("/authorizer/schedule");

    }
  }

  forgetPassword = () => {
    // this.props.changeStatus;
    this.props.history.push("/forgetpassword");
  }

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-z  A-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  logIn = () => {
    console.log("sami", this.state.password.length);
    if (this.state.email == "" || !this.validateEmail(this.state.email)) {
      toastr.error("Invalid email address!");
    } else if (this.state.password.length <= 7) {
      toastr.error("Password must have eight or more characters!");
    } else {
      let obj = {
        email: this.state.email,
        password: this.state.password
      };
      this.props.reviewerSignin(obj, () => this.props.history.push("/reviewer/dashboard/employers"));
      this.setState({
        loader: true
      });
    }
  };

  render() {
    const { email, password } = this.state;
    const { showMessage, alertMessage } = this.props;
    const { loader } = this.state;

    return (
      // <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      //   <div className="app-login-main-content">
      //     <div className="app-logo-content d-flex align-items-center justify-content-center">
      //       <Link className="logo-lg" to="/" title="Jambo">
      //         <img
      //           width="100%"
      //           src={require("assets/unhr-images/Logo-Extra.png")}
      //           alt="jambo"
      //           title="jambo"
      //         />
      //       </Link>
      //     </div>

      //     <div className="app-login-content">
      //       <div className="app-login-header mb-4">
      //         <h1>Unohr Reviewer Panel</h1>
      //       </div>

      //       <div className="app-login-form">
      //         <form>
      //           <fieldset>
      //             <TextField
      //               label={<IntlMessages id="appModule.email" />}
      //               fullWidth
      //               onChange={event =>
      //                 this.setState({ email: event.target.value })
      //               }
      //               defaultValue={email}
      //               margin="normal"
      //               className="mt-1 my-sm-3"
      //             />
      //             <TextField
      //               type="password"
      //               label={<IntlMessages id="appModule.password" />}
      //               fullWidth
      //               onChange={event =>
      //                 this.setState({ password: event.target.value })
      //               }
      //               defaultValue={password}
      //               margin="normal"
      //               className="mt-1 my-sm-3"
      //             />

      //             <div className="mb-3 d-flex align-items-center justify-content-between">
      //               <Button
      //                 onClick={this.logIn}
      //                 variant="contained"
      //                 color="primary"
      //               >
      //                 <IntlMessages id="appModule.signIn" />
      //               </Button>
      //             </div>
      //             <div onClick={this.forgetPassword} style={{cursor: "pointer", textDecoration: 'underline'}}>Forget Password?</div>
      //           </fieldset>
      //         </form>
      //       </div>
      //     </div>
      //   </div>
      //   {loader && (
      //     <div className="loader-view">
      //       <CircularProgress />
      //     </div>
      //   )}
      //   {showMessage && NotificationManager.error(alertMessage)}
      //   <NotificationContainer />
      // </div>
      <div style={{ width: "100%", overflowY: "auto" }}>
        <Navbar />
        <div className="wrapper">
          <div style={{ overflow: "scroll" }}>
            {/* <div style={{ position: 'absolute', left: 40, top: 30 }}>
              <IconButton
                onClick={() => this.props.history.push("/")}
                style={{
                  backgroundColor: 'white',
                }}
              >
                <HomeIcon
                  style={{
                    color: '#397e3d',
                  }}
                />
              </IconButton>
            </div> */}
            <div className="form_container">
              <p className="title">Unohr Reviewer Panel Forget </p>
              <p className="title_description">
                Please login using your account
              </p>

              <div>
                <p className="form_group_label_account">EMAIL</p>
                <input
                  className={`custom_input mt-1 my-sm-3`}
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={event =>
                    this.setState({ email: event.target.value })
                  }
                  defaultValue={email}
                  margin="normal"
                />
              </div>
              <p className="form_group_label_account">PASSWORD</p>
              <input
                className={`custom_input mt-1 my-sm-3`}
                type="password"
                label={<IntlMessages id="appModule.password" />}
                fullWidth
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
                defaultValue={password}
                margin="normal"
              />
              <Button
                onClick={this.logIn}
                style={{
                  marginTop: 10,
                  color: 'white',
                  backgroundColor: '#397e3d',
                  borderRadius: '14px',
                  fontWeight: 700,
                  fontSize: '16px',
                  width: '100%',
                  height: '60px',
                  boxShadow: '#397e3d 0px 30px 30px -20px',
                  marginBottom: '10px',
                }}
              >
                {loader ? (
                  <div className="loader-view">
                    <CircularProgress />
                  </div>
                ) :
                  <IntlMessages id="appModule.signIn" />}

              </Button>
              <div onClick={this.forgetPassword} style={{ cursor: "pointer", marginTop: 20 }}>Forget Password?</div>
            </div>

            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer />

          </div>
        </div>
        <div>
          <div
            style={{
              marginLeft: '0px',
              width: '100%',
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#f2f4f7',
              color: 'black',
            }}
          >
            © {new Date().getFullYear()} helpara.com - All Rights Reserved
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hasErrored: state.auth.hasErrored,
    loader: state.auth.loader,
    user: state.auth.user,
    userStatus: state.auth.userStatus,
    userType: state.auth.userType,
    isLoading: state.auth.isLoading,
    alertMessage: state.auth.alertMessage,
    showMessage: state.auth.showMessage
  };
};

export default connect(
  mapStateToProps,
  {
    reviewerSignin,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGoogleSignIn,
    userGithubSignIn,
    userTwitterSignIn,
    addLog
  }
)(SignIn);
