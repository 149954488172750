import {
  INSERT_STATES_WAGES,
  GET_STATES_WAGES,
  INSERT_STATES_WAGES_ERR,
  GET_STATES_WAGES_ERR,
  INSERT_NEW_HIRES,
  GET_NEW_HIRES,
  INSERT_NEW_HIRES_ERR,
  GET_NEW_HIRES_ERR,
  GET_STATES_WAGES_FOR_EXPORT,
  GET_STATES_WAGES_FOR_EXPORT_ERR,
  GET_NEW_HIRES_FOR_EXPORT,
  GET_NEW_HIRES_FOR_EXPORT_ERR,
  GET_WC_STATES,
  GET_WC_STATES_ERR,
  INSERT_WC_STATES,
  INSERT_WC_STATES_ERR,
  GET_WC_STATES_FOR_EXPORT,
  GET_WC_STATES_FOR_EXPORT_ERR,
  ////
  INSERT_DISTRICTS,
  INSERT_DISTRICTS_ERR,
  GET_DISTRICTS,
  GET_DISTRICTS_ERR,
  GET_DISTRICTS_EXPORT,
  GET_DISTRICTS_EXPORT_ERR,
  SEARCH_DISTRICTS,
  SEARCH_DISTRICTS_ERR,
  INSERT_INDUSTRY,
  INSERT_INDUSTRY_ERR,
  GET_INDUSTRY,
  GET_INDUSTRY_ERR,
  GET_INDUSTRY_EXPORT,
  GET_INDUSTRY_EXPORT_ERR,
} from "constants/ActionTypes";

var initialState = {
  loader: new Date(),
  statesWages: [],
  newHires: [],
  wcStates: [],
  districts: [],
  industry: [],
  searchedDistricts: [],
  searchStatus: "not done",
  statesWagesStatus: "not done",
  insertStatesWagesStatus: "not done",
  insertNewHiresStatus: "not done",
  getStatesWagesStatus: "not done",
  getNewHiresStatus: "not done",
  getExportStatesWagesStatus: "not done",
  getExportNewHiresStatus: "not done",
  getWcStatesStatus: "not done",
  insertWcStatesStatus: "not done",
  getExportWcStatesStatus: "not done",
  insertDistrictsStatus: "not done",
  getExportDistrictsStatus: "not done",
  getDistrictsStatus: "not done",
  insertIndustryStatus: "not done",
  getExportIndustryStatus: "not done",
  getIndustryStatus: "not done",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INSERT_STATES_WAGES:
      return {
        ...state,
        loader: new Date(),
        statesWages: action.payload,
        insertStatesWagesStatus: "done",
        getExportStatesWagesStatus: "not done",
        getStatesWagesStatus: "not done",
      };
    case GET_STATES_WAGES:
      //   console.log("Get Wages Reducer: ", action.payload);
      return {
        ...state,
        loader: new Date(),
        statesWages: action.payload,
        getStatesWagesStatus: "done",
        insertNewHiresStatus: "not done",
        getExportStatesWagesStatus: "not done",
      };
    case INSERT_NEW_HIRES:
      return {
        ...state,
        loader: new Date(),
        newHires: action.payload,
        insertNewHiresStatus: "done",
      };
    case GET_NEW_HIRES:
      console.log("Get Wages Reducer: ", action.payload);
      return {
        ...state,
        loader: new Date(),
        newHires: action.payload,
        getNewHiresStatus: "done",
      };
    case INSERT_STATES_WAGES_ERR:
      return {
        ...state,
        loader: new Date(),
        insertStatesWagesStatus: "error",
        getExportStatesWagesStatus: "not done",
        getStatesWagesStatus: "not done",
      };
    case GET_STATES_WAGES_ERR:
      return {
        ...state,
        loader: new Date(),
        getStatesWagesStatus: "error",
        getExportStatesWagesStatus: "not done",
        insertNewHiresStatus: "not done",
      };
    case INSERT_NEW_HIRES_ERR:
      return {
        ...state,
        loader: new Date(),
        insertNewHiresStatus: "error",
      };
    case GET_NEW_HIRES_ERR:
      return {
        ...state,
        loader: new Date(),
        getNewHiresStatus: "error",
      };
    case GET_STATES_WAGES_FOR_EXPORT:
      return {
        ...state,
        loader: new Date(),
        statesWages: action.payload,
        getExportStatesWagesStatus: "done",
        getStatesWagesStatus: "not done",
      };
    case GET_STATES_WAGES_FOR_EXPORT_ERR:
      return {
        ...state,
        loader: new Date(),
        getExportStatesWagesStatus: "error",
        getStatesWagesStatus: "not done",
      };
    case GET_NEW_HIRES_FOR_EXPORT:
      return {
        ...state,
        loader: new Date(),
        newHires: action.payload,
        getExportNewHiresStatus: "done",
        getNewHiresStatus: "not done",
      };
    case GET_NEW_HIRES_FOR_EXPORT_ERR:
      return {
        ...state,
        loader: new Date(),
        getExportNewHiresStatus: "error",
        getNewHiresStatus: "not done",
      };
    case INSERT_WC_STATES:
      return {
        ...state,
        loader: new Date(),
        wcStates: action.payload,
        insertWcStatesStatus: "done",
        getExportWcStatesStatus: "not done",
        getWcStatesStatus: "not done",
      };
    case INSERT_WC_STATES_ERR:
      return {
        ...state,
        loader: new Date(),
        insertWcStatesStatus: "error",
        getExportWcStatesStatus: "not done",
        getWcStatesStatus: "not done",
      };
    case GET_WC_STATES:
      console.log("Get Wages Reducer: ", action.payload);
      return {
        ...state,
        loader: new Date(),
        wcStates: action.payload,
        getWcStatesStatus: "done",
        insertWcStatesStatus: "not done",
        getExportWcStatesStatus: "not done",
      };
    case GET_WC_STATES_ERR:
      return {
        ...state,
        loader: new Date(),
        getWcStatesStatus: "error",
        insertWcStatesStatus: "not done",
        getExportWcStatesStatus: "not done",
      };
    case GET_WC_STATES_FOR_EXPORT:
      return {
        ...state,
        loader: new Date(),
        newHires: action.payload,
        getExportWcStatesStatus: "done",
        getWcStatesStatus: "not done",
        insertWcStatesStatus: "not done",
      };
    case GET_WC_STATES_FOR_EXPORT_ERR:
      return {
        ...state,
        loader: new Date(),
        getExportWcStatesStatus: "error",
        getWcStatesStatus: "not done",
        insertWcStatesStatus: "not done",
      };
    ///////////// DISTRICTS ////////////////////////
    case GET_DISTRICTS:
      //   console.log("Get districts Reducer: ", action.payload);
      return {
        ...state,
        loader: new Date(),
        districts: action.payload,
        insertDistrictsStatus: "not done",
        getExportDistrictsStatus: "not done",
        getDistrictsStatus: "done",
      };

    case GET_DISTRICTS_ERR:
      return {
        ...state,
        loader: new Date(),
        getDistrictsStatus: "error",
        getExportDistrictsStatus: "not done",
        insertDistrictsStatus: "not done",
      };

    case INSERT_DISTRICTS:
      return {
        ...state,
        loader: new Date(),
        districts: action.payload,
        insertDistrictsStatus: "done",
        getExportDistrictsStatus: "not done",
        getDistrictsStatus: "not done",
      };
    case INSERT_DISTRICTS_ERR:
      return {
        ...state,
        loader: new Date(),
        insertDistrictsStatus: "error",
        getExportDistrictsStatus: "not done",
        getDistrictsStatus: "not done",
      };
    case GET_DISTRICTS_EXPORT:
      return {
        ...state,
        loader: new Date(),
        districts: action.payload,
        getExportDistrictsStatus: "done",
        getDistrictsStatus: "not done",
      };
    case GET_DISTRICTS_EXPORT_ERR:
      return {
        ...state,
        loader: new Date(),
        getExportDistrictsStatus: "error",
        getDistrictsStatus: "not done",
        insertDistrictsStatus: "not done",
      };
    case SEARCH_DISTRICTS:
      return {
        ...state,
        loader: new Date(),
        searchedDistricts: action.payload,
        searchStatus: "done",
      };
    case SEARCH_DISTRICTS_ERR:
      return {
        ...state,
        loader: new Date(),
        searchStatus: "error",
        getDistrictsStatus: "not done",
      };
    ///////////// INDUSTRY ////////////////////////
    case GET_INDUSTRY:
      return {
        ...state,
        loader: new Date(),
        industry: action.payload,
        insertIndustryStatus: "not done",
        getExportIndustryStatus: "not done",
        getIndustryStatus: "done",
      };

    case GET_INDUSTRY_ERR:
      return {
        ...state,
        loader: new Date(),
        insertIndustryStatus: "not done",
        getExportIndustryStatus: "not done",
        getIndustryStatus: "error",
      };

    case INSERT_INDUSTRY:
      return {
        ...state,
        loader: new Date(),
        industry: action.payload,
        insertIndustryStatus: "done",
        getExportIndustryStatus: "not done",
        getIndustryStatus: "not done",
      };
    case INSERT_INDUSTRY_ERR:
      return {
        ...state,
        loader: new Date(),
        insertIndustryStatus: "error",
        getExportIndustryStatus: "not done",
        getIndustryStatus: "not done",
      };
    case GET_INDUSTRY_EXPORT:
      return {
        ...state,
        loader: new Date(),
        industry: action.payload,
        getExportIndustryStatus: "done",
        getIndustryStatus: "not done",
      };
    case GET_INDUSTRY_EXPORT_ERR:
      return {
        ...state,
        loader: new Date(),
        insertIndustryStatus: "not done",
        getExportIndustryStatus: "done",
        getIndustryStatus: "not done",
      };
    default:
      return state;
  }
}
