import axios from "axios";
import { toast } from "react-toastify";
import { db } from "../../firebase/firebase";
export const ADDNEWEMPLOYEE = "ADDNEWEMPLOYEE";
export const ADDNEWEMPLOYEEERR = "ADDNEWEMPLOYEEERR";

export const ADDNEWEMPLOYER = "ADDNEWEMPLOYER";
export const ADDNEWEMPLOYERERR = "ADDNEWEMPLOYERERR";

export const GT_ALL_EMPLOYERS = "GT_ALL_EMPLOYERS";

export const GT_ALL_EMPLOY = "GT_ALL_EMPLOY";
export const GET_EMP_WK_STATUS = "GET_EMP_WK_STATUS";
export const GET_EMP_STATUS_ERR = "GET_EMP_STATUS_ERR";
export const GET_EMP_PAYPERIOD = "GET_EMP_PAYPERIOD";
export const GET_EMP_PAYPERIOD_ERR = "GET_EMP_PAYPERIOD_ERR";
export const CREATE_PAYSTUB = "CREATE_PAYSTUB";
export const CREATE_PAYSTUB_ERR = "CREATE_PAYSTUB_ERR";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const VERIFY_NO = "VERIFY_NO";
export const GET_SYS_DOC = "GET_SYS_DOC";
export const GET_SYS_DOC_ERR = "GET_SYS_DOC_ERR";


//ForgetPassword
export function registerEmployer(data) {
  console.log("at action sign up ERR", data);
  return dispatch => {
    axios
      .post(
        "https://us-central1-promising-saga-232017.cloudfunctions.net/restfullapi/employerRegisteration",
        data
      )
      .then(res => {
        console.log("response", res.data);
        if (res.data == "successfully registered user") {
          console.log("Successfully created employeer!");
          toast.success("Successfully registered employer!");
          dispatch({ type: ADDNEWEMPLOYER });
        } else {
          console.log("Error Hass been Occoured! Try again", res.data);
          toast.error("Error Hass been Occoured! Try again!");
          dispatch({ type: ADDNEWEMPLOYERERR });
        }
      })
      .catch(err => {
        toast.error("Error Hass been Occoured! Try again!");
        console.error("err from net", err);
        dispatch({ type: ADDNEWEMPLOYERERR });
      });
  };
}

//ForgetPassword
export function addNewEmployee(data) {
  console.log("at action file to invite employee", data);
  return dispatch => {
    axios
      .post(
        "https://us-central1-promising-saga-232017.cloudfunctions.net/restfullapi/employeeRegisteration",
        data
      )
      .then(res => {
        console.log("res", res.data);

        if (res.data == "successfully created employe") {
          console.log("Successfully created employee!");
          toast.success("Successfully created employee!");
          dispatch({ type: ADDNEWEMPLOYEE, payload: data });
        } else {
          console.log("Error Has been Occoured! Try again");
          toast.error("Error Has been Occoured! Try again");

          dispatch({ type: ADDNEWEMPLOYEEERR });
        }
      })

      .catch(err => {
        toast.error("Error Has been Occoured! Try again");
        console.error("err", err);

        dispatch({ type: ADDNEWEMPLOYEEERR });
      });
  };
}

// Get all employees
export function getEmployees(data) {
  return dispatch => {
    db.collection("users")
      .where("employeruid", "==", data)
      // .where("status", "==", "active")
      .onSnapshot(function(querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function(doc) {
          const data = doc.data();
          const id = doc.id;

          datatoStore.push({ id, ...data });
          return datatoStore;
        });
        console.log("data to store", datatoStore);

        dispatch({
          type: GT_ALL_EMPLOY,
          payload: datatoStore
        });
      });
  };
}

// Get all employers
export function getEmployers() {
  return dispatch => {
    db.collection("users")
      // .where("status", "==", "active")
      .onSnapshot(function(querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function(doc) {
          const data = doc.data();
          const id = doc.id;

          datatoStore.push({ id, ...data });
          return datatoStore;
        });
        console.log("data to store", datatoStore);

        dispatch({
          type: GT_ALL_EMPLOYERS,
          payload: datatoStore
        });
      });
  };
}

// get employees week timeline

export function getEmployeStatus(empUid) {
  console.log("======data fro action==============================");
  console.log(empUid);
  console.log("====================================");

  return dispatch => {
    db.collection("attendance")
      .where("employeeUid", "==", empUid)
      // .orderBy("day")
      .onSnapshot(
        function(querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(doc => {
            let data = doc.data();

            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          console.log(
            "========action checking data============================"
          );
          console.log(datatoStore);
          console.log("====================================");

          dispatch({
            type: GET_EMP_WK_STATUS,
            payload: datatoStore
          });
        },
        function(error) {
          dispatch({
            type: GET_EMP_STATUS_ERR
          });
        }
      );
  };
}

// get pay period

export function getPayPariod(id) {
  return dispatch => {
    db.collection("payperiod")
      .where("employeeUid", "==", id)
      .onSnapshot(
        function(querySnapshot) {
          let datatoStore = [];
          querySnapshot.forEach(function(doc) {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });

            return datatoStore;
          });
          console.log("===========datatoStore=========================");
          console.log(datatoStore);
          console.log("====================================");

          dispatch({
            type: GET_EMP_PAYPERIOD,
            payload: datatoStore
          });
        },
        function(error) {
          dispatch({
            type: GET_EMP_PAYPERIOD_ERR
          });
        }
      );
  };
}

// for creating paystub
export function createPayStub(payStubData) {
  console.log("===========id from action=========================");
  console.log(payStubData);
  console.log("====================================");

  return dispatch => {
    axios
      .post(
        "https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/generatePayStubs",
        payStubData
      )
      .then(res => {
        if (res.data == "successfully work done") {
          console.log("res", res.data);
          dispatch({
            type: CREATE_PAYSTUB,
            payload: payStubData.id
          });
        } else {
          console.log("else");

          dispatch({ type: CREATE_PAYSTUB_ERR });
        }
        //err
      })
      .catch(err => {
        //err
        console.error("err", err);
        dispatch({ type: CREATE_PAYSTUB_ERR });
      });
  };
}

// verify number
export function verifyNumber(data) {
  return dispatch => {
    axios
      .post(
        " https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/sendmessage",
        data
      )
      .then(res => {
        console.log("res", res.data);
        if (res.data == "successfully sent") {
          console.log("res", res.data);
          // dispatch({
          //   type: CREATE_PAYSTUB,
          //   payload: payStubData.id
          // });
        } else {
          console.log("else");

          // dispatch({ type: CREATE_PAYSTUB_ERR });
        }
        //err
      })
      .catch(err => {
        //err
        console.error("err", err);
        // dispatch({ type: CREATE_PAYSTUB_ERR });
      });
  };
}

// verify number
export function verifyEmail(data) {
  return dispatch => {
    axios
      .post(
        " https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/sendemail",
        data
      )
      .then(res => {
        console.log("res", res.data);
        if (res.data == "successfully sent") {
          console.log("res", res.data);
          // dispatch({
          //   type: CREATE_PAYSTUB,
          //   payload: payStubData.id
          // });
        } else {
          console.log("else");
          // dispatch({ type: CREATE_PAYSTUB_ERR });
        }
        //err
      })
      .catch(err => {
        //err
        console.error("err", err);
      });
  };
}

// get all system docs
export function getSystemDocs() {
  return dispatch => {
    db.collection("systemdocuments").onSnapshot(
      function(querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function(doc) {
          const data = doc.data();
          const id = doc.id;

          datatoStore.push({ id, ...data });
        });
        console.log("data to store", datatoStore);

        dispatch({
          type: GET_SYS_DOC,
          payload: datatoStore
        });
      },
      function(error) {
        console.log("=================error===================");
        console.log(error);
        console.log("====================================");
        dispatch({
          type: GET_SYS_DOC_ERR
        });
      }
    );
  };
}

