import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";

import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import CircularProgress from "@material-ui/core/CircularProgress";
import mails from "./data/mails";
import folders from "./data/folders";
import filters from "./data/filters";
import labels from "./data/labels";
import options from "./data/options";
import MailList from "./routes";
import CustomScrollbars from "util/CustomScrollbars";
import StateSidebar from "./routes/sideBar";
class Mail extends Component {
  displayMail = (currentMail, folderMails, noContentFoundMessage) => {
    return (
      <div className="module-box-column" style={{ height: "100%" }}>
        {currentMail === null ? (
          folderMails.length === 0 ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                height: "100%"
              }}
            >
              {noContentFoundMessage}
            </div>
          ) : (
            <MailList
              mails={folderMails}
              onMailSelect={this.onMailSelect.bind(this)}
              width={this.props.width}
            />
          )
        ) : (
          <div />
        )}
      </div>
    );
  };

  constructor() {
    super();
    this.state = {
      noContentFoundMessage: "No Employee Found for this state",
      drawerState: false,
      loader: true,
      currentMail: null,
      folderMails: mails.filter(mail => mail.folder === 0)
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loader: false });
    }, 1500);
  }

  onMailSelect(mail) {
    this.setState({
      loader: true,
      currentMail: mail
    });
    setTimeout(() => {
      this.setState({ loader: false });
    }, 1500);
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  render() {
    const {
      loader,
      currentMail,
      folderMails,
      noContentFoundMessage
    } = this.state;
    const { width } = this.props;
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="app-module">
            <div className="d-block d-xl-none">
              <Drawer
                open={this.state.drawerState}
                onClose={this.onToggleDrawer.bind(this)}
              >
                <StateSidebar width={width} />
              </Drawer>
            </div>
            <div className="app-module-sidenav d-none d-xl-flex">
              <StateSidebar />
            </div>

            <div className="module-box">
              <div className="module-box-header">
                <IconButton
                  className="drawer-btn d-block d-xl-none"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                >
                  <i className="zmdi zmdi-menu" />
                </IconButton>
                {/* state detail here */}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} className="lsSend">
                    <div className="stateStatus">
                      <span className="sender-name text-dark">
                        Last Sent for stateName:
                      </span>
                      <span className="sender-name text-dark">25/01/2020</span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    alignItems="flex-end"
                    justify="flex-end"
                    direction="row"
                  >
                    <div className="sendAll">
                      <Button variant="contained" color="primary">
                        Approve All
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div
                className="module-box-content"
                style={{ height: "97%", backgroundColor: `#f9fafb` }}
              >
                {loader ? (
                  <div
                    className="loader-view"
                    style={{
                      height:
                        this.props.width >= 1200
                          ? "calc(100vh - 265px)"
                          : "calc(100vh - 245px)"
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  this.displayMail(
                    currentMail,
                    folderMails,
                    noContentFoundMessage
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width } = settings;
  return { width };
};
export default connect(mapStateToProps)(Mail);
