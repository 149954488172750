export default [
  {
    'id': 0,
    'handle': 'all shop cards',
    'title': 'All Shop Cards',
    'icon': 'card-giftcard'
  },
  {
    'id': 1,
    'handle': 'Selected',
    'title': 'Selected Cards',
    'icon': 'check'
  },
  
];