import React, { Component } from "react";
import SimpleExpansionPanel from "./routes";
import CardBox from "components/CardBox";
import ContainerHeader from "components/ContainerHeader";

class ExpansionPanel extends Component {
  state = {
    searchQuery: ""
  };

  onTextChangeHandler = event => {
    this.setState({
      searchQuery: event.target.value
    });
  };




  render() {
    const {allStateDocs} = this.props
    return (
      <div className="animated slideInUpTiny animation-duration-3 stateScheduleCon">
        <ContainerHeader
          title="State Documents"
          searchQuery={this.state.searchQuery}
          onTextChangeHandler={this.onTextChangeHandler}
        />
        <div className="row mb-md-3">
          <CardBox
            styleName="col-lg-12"
            cardStyle="bg-transparent no-shadow p-0"
          >
            <SimpleExpansionPanel searchQuery={this.state.searchQuery} />
          </CardBox>
        </div>
      </div>
    );
  }
}



export default ExpansionPanel


