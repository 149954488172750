import axios from "axios";
import toastr from "toastr";
import { reviewer_url, addNewReviewerApi } from "../Endpoint";
export const CREATE_REVIEWER = "CREATE_REVIEWER";
export const CREATE_REVIEWER_ERR = "CREATE_REVIEWER_ERR";

export const createReviewer = (data, cb) => {
  return (dispatch) => {
    axios
      .post(`${reviewer_url}${addNewReviewerApi}`, data)
      .then(function (res) {
        console.log("RESPONSE FROM SERVER REVIEWER CREATION:", res);

        if (res.data === "error has been ocured") {
        }

        if (res.data === "successfully registered") {
          toastr.success("Reviewer created successfully");
          dispatch({
            type: CREATE_REVIEWER,
            payload: data,
          });
          cb("success");
        } else {
          toastr.error("Error occured. Please try again later");
          dispatch({
            type: CREATE_REVIEWER_ERR,
          });
          cb("error");
        }
      })
      .catch(function (error) {
        console.log("ERROR FROM SERVER REVIEWER CREATION:", error);
        toastr.error("Error occured. Please try again later");
        dispatch({
          type: CREATE_REVIEWER_ERR,
        });
        cb("error");
      });
  };
};
