import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../../util/asyncComponent';
import StateDoc from './routes/stateDocuments'

const Dashboard = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/documents/manage`}/>
      <Route path={`${match.url}/manage`} component={asyncComponent(() => import('./routes/verified documents'))}/>

      <Route path={`${match.url}/unverified_documents`} component={asyncComponent(() => import('./routes/unverified documents'))}/>

      <Route path={`${match.url}/new_document`} component={asyncComponent(() => import('./routes/NewDocuments'))}/>
      <Route path={`${match.url}/statedocuments`} component={StateDoc}/>

      <Route component={asyncComponent(() => import('../../../app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Dashboard;