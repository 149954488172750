import { db } from "../../firebase/firebase";
import { toast } from "react-toastify";


export function addLog(data) {
    return dispatch => {
      db.collection("logs")
        .add(data)
        .then(function() {
        //   const id = docRef.id;
        //   // const Taskdata = docRef.data();
        //   const dataToStore = { id, ...data };
  
        //   dispatch({
        //     type: ADD_TASK,
        //     payload: dataToStore
        //   });
        console.log("Wrote: ", data, " in log");
        })
        .catch(function(error) {
          toast.error("Error occured. Could not write log");
        });
    // console.log("log: ", data);
    };
  }