import React, { Component } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";

export default class DateAndTimePickers extends Component {
  render() {
    return (
      <div key="datetime_default" className="picker">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            fullWidth
            value={this.props.date}
            showTabs={false}
            onChange={this.props.handleDateChange}
            leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
            rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}
