import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import CardBox from "components/CardBox";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import Expansion from "./expansion";
import { getAll } from "actions/authorizerAction/allDocs";

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      reqSendStatus: true
    };
  }

  searchingForName = searchQuery => {
    return function(document) {
      return (
        document.doc_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        moment(document.createdAt.toDate())
          .format("ll")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        !searchQuery
      );
    };
  };

  componentDidMount() {
    if (this.state.reqSendStatus) {
      this.props.getAll(this.resAllDoc);
    }
  }

  resAllDoc = type => {
    if (type === "success") {
      this.setState({
        reqSendStatus: false,
        loader: false
      });
    } else {
      this.setState({
        reqSendStatus: true,
        loader: false
      });
    }
  };

  onUpdateHandler = id => {
    this.setState({ updateLoader: true });
    let data = {
      doc_id: id,
      record: {
        status: "unverified"
      }
    };
    this.props.addVerifiedDocuments(data);
  };

  render() {
    const { classes, allStateDocs } = this.props;
    const { loader } = this.state;

    return (
      <div className={classes.root}>
        {loader ? (
          <Grid item align="center">
            <ScaleLoader color={"#123abc"} />
          </Grid>
        ) : (
          <div>
            {allStateDocs.length > 0 ? (
              allStateDocs
                .filter(this.searchingForName(this.props.searchQuery))
                .map((doc, index) => {
                  return (
                    <Expansion doc={doc} index={index} classes={classes} />
                  );
                })
            ) : (
              <CardBox styleName="col-lg-12">
                <div>No, Record Found!</div>
              </CardBox>
            )}
          </div>
        )}
      </div>
    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  allStateDocs: state.allDocs.allStateDocs
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: 5
  },
  Number: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontWeight: "bold"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 25
  }
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getAll })
)(Documents);
