import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
} from "constants/ActionTypes";

import toastr from "toastr";
import { db, auth } from "../firebase/firebase";

export const LOGIN = "LOGIN";
export const LOGINERR = "LOGINERR";
export const LOGIN_REVIEWER = "LOGIN_REVIEWER";
export const LOGIN_REVIEWER_ERR = "LOGIN_REVIEWER_ERR";
export const LOGOUT = "LOGOUT";
export const LOGOUTERR = "LOGOUTERR";
export const GETUSER = "GETUSER";
export const GETUSERERR = "GETUSERERR";
export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const CHANGE_ADDRESS_ERR = "CHANGE_ADDRESS_ERR";
export const CHANGE_STATUS = "CHANGE_STATUS";

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = (data, cb) => {
  return (dispatch) => {
    db.collection("admin")
      .where("email", "==", data.email)
      .where("type", "==", "admin")

      .get()
      .then(function (querySnapshot) {
        let datatoStore = {};
        querySnapshot.forEach(function (doc) {
          let data = doc.data();
          let docid = doc.id;
          let final = {
            ...data,
            docid,
          };
          datatoStore = final;
        });

        if (datatoStore.adminId) {
          auth
            .signInWithEmailAndPassword(data.email, data.password)
            .then((user) => {
              if (user) {
                console.log("User: ", user);
                let data = {
                  ...datatoStore,
                  uid: user.uid,
                };

                toastr.success("Succesfull Login!");

                dispatch({
                  type: LOGIN,
                  payload: data,
                });

                cb && cb();
              } else {
                dispatch({
                  type: LOGINERR,
                  payload: new Date(),
                });
              }
              // auth.onAuthStateChanged(user => {

              // });

              // let data = {
              //   email: user.email,
              //   uid: user.uid
              // };
            })
            .catch(function (error) {
              // Handle Errors here.

              console.log("err from fire", error);

              toastr.error("Wrong Password try again!");

              dispatch({
                type: LOGINERR,
                payload: new Date(),
              });
              // ...
            });
        } else {
          console.log("User not Registerd yet,Please registerd first!");

          toastr.error("User not Registerd yet,Please registerd first!");

          dispatch({
            type: LOGINERR,
            payload: new Date(),
          });
        }
      })
      .catch(function (error) {
        console.log("Error occoured Try again!");

        toastr.error("Error occoured Try again!");

        dispatch({
          type: LOGINERR,
          payload: new Date(),
        });
      });
  };
};

export const reviewerSignin = (data,cb) => {
  return (dispatch) => {
    db.collection("admin")
      .where("email", "==", data.email)
      // .where("type", "==", "reviewer")

      .get()
      .then(function (querySnapshot) {
        let datatoStore = {};
        querySnapshot.forEach(function (doc) {
          let data = doc.data();
          let docid = doc.id;
          let final = {
            ...data,
            docid,
          };
          datatoStore = final;
        });

        if (datatoStore.adminId) {
          auth
            .signInWithEmailAndPassword(data.email, data.password)
            .then((user) => {
              if (user) {
                let data = {
                  ...datatoStore,
                  uid: user.uid,
                };

                toastr.success("Succesfull Login!");

                dispatch({
                  type: LOGIN,
                  payload: data,
                });
                cb();
                console.log("cb",cb)
              } else {
                toastr.error("Error Occoured try again!");
                dispatch({
                  type: LOGINERR,
                  payload: new Date(),
                });
              }
              // auth.onAuthStateChanged(user => {

              // });

              // let data = {
              //   email: user.email,
              //   uid: user.uid
              // };
            })
            .catch(function (error) {
              // Handle Errors here.

              console.log("err from fire", error);

              toastr.error("Wrong Password try again!");

              dispatch({
                type: LOGINERR,
                payload: new Date(),
              });
              // ...
            });
        } else {
          console.log("User not Registerd yet,Please registerd first!");

          toastr.error("User not Registerd yet,Please registerd first!");

          dispatch({
            type: LOGINERR,
            payload: new Date(),
          });
        }
      })
      .catch(function (error) {
        console.log("Error occoured Try again!");

        toastr.error("Error occoured Try again!");

        dispatch({
          type: LOGINERR,
          payload: new Date(),
        });
      });
  };
};

export function startGetCurrentUser() {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("startGetCurrentUser onAuthStateChanged: ", user)
        db.collection("admin")
          .where("email", "==", user.email)
          .get()
          .then(function (querySnapshot) {
            let datatoStore = {};
            querySnapshot.forEach(function (doc) {
              let data = doc.data();
              let docid = doc.id;
              let final = {
                ...data,
                docid,
              };
              datatoStore = final;
            });

            if (Object.entries(datatoStore).length !== 0) {
              let datas = {
                ...datatoStore,
                uid: user.uid,
              };
              dispatch({
                type: GETUSER,
                payload: datas,
              });
            } else {
              dispatch({
                type: GETUSERERR,
                payload: "nill",
              });
            }
          });
      } else {
        dispatch({
          type: GETUSERERR,
          payload: "nill",
        });
      }
    });
  };
}

export function startLogout() {
  return (dispatch) => {
    auth
      .signOut()
      .then(function () {
        toastr.success("Successfully Logout");
        console.log("startLogout Successfully");

        dispatch({
          type: LOGOUT,
        });
      })
      .catch(function (error) {
        console.log("startLogout: ", error);
        toastr.error("Error occoured Try again!");
      });
  };
}

//ForgetPassword
export function startRessetPassword(data) {
  return (dispatch) => {
    auth
      .sendPasswordResetEmail(data)
      .then(function () {
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
      });
  };
}

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

// For change status of the user
export const changeStatus = () => {
  return {
    type: CHANGE_STATUS,
  };
};
