import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function(event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function(fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-assignment zmdi-hc-fw" />
              <span className="nav-text">Review Tasks</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/employees"
                >
                  <i className="zmdi zmdi-assignment-account zmdi-hc-fw" />
                  <span className="nav-text">Employee Tasks</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/completed"
                >
                  <i className="zmdi zmdi-assignment-check zmdi-hc-fw" />
                  <span className="nav-text">Completed Tasks</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/employer"
                >
                  <i className="zmdi zmdi-assignment-account zmdi-hc-fw" />
                  <span className="nav-text">Employer Tasks</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-money zmdi-hc-fw" />
              <span className="nav-text">Review Payroll</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/timesheets"
                >
                  <i className="zmdi zmdi-time zmdi-hc-fw" />
                  <span className="nav-text">Time Sheets</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/paystubs"
                >
                  <i className="zmdi zmdi-collection-pdf zmdi-hc-fw" />
                  <span className="nav-text">Paystubs</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-accounts zmdi-hc-fw" />
              <span className="nav-text">Review Employees</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/viewemployees"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">All Employees</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-calendar-note zmdi-hc-fw" />
              <span className="nav-text">Review Schedule</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/schedule"
                >
                  <i className="zmdi zmdi-calendar zmdi-hc-fw" />
                  <span className="nav-text">View Schedule</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/reviewer/dashboard/inbox"
                >
                  <i className="zmdi zmdi-email zmdi-hc-fw" />
                  <span className="nav-text">Inbox</span>
                </NavLink>
              </li>
            </ul>
          </li>

          
          {/* <li className="nav-header">
            <IntlMessages id="sidebar.inBuiltApp" />
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/admin/dashboard/setup">
              <i className="zmdi zmdi-code-setting" />
              <span className="nav-text">Setup API's</span>
            </NavLink>
          </li> */}

          {/* <li className="menu no-arrow">
            <NavLink to="/app/to-do">
              <i className="zmdi zmdi-check-square zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.toDo" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/contact">
              <i className="zmdi zmdi-account-box zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.contact" />
              </span>
            </NavLink>
          </li> */}

          {/* <li className="menu no-arrow">
            <NavLink to="/app/chat">
              <i className="zmdi zmdi-comment zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.chat" />
              </span>
            </NavLink>
          </li> */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
