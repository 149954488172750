import { db } from "../../firebase/firebase";
import { toast } from "react-toastify";
// add task type const
export const ADD_TASK = "ADD_TASK";
// add paperwork type const
export const PAPERWORK_TASK = "PAPERWORK_TASK";
// get all task type const
export const GET_TASK = "GET_TASK";

// update task type const
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_ERR = "UPDATE_TASK_ERR";

// delete task type const
export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_ERR = "DELETE_TASK_ERR";
// completed task add type const
export const COMPL_TASK = "COMPL_TASK";
export const COMPL_TASK_ERR = "COMPL_TASK_ERR";
// Get all completed task type const
export const GT_COMP_TASK = "GT_COMP_TASK";
// add task type own task
export const ADD_OWN_TASK = "ADD_OWN_TASK";
// Get task type own task
export const GET_EMPLOYER_TASK = "GET_EMPLOYER_TASK";

// delete task type const
export const DELETE_OWN_TASK = "DELETE_OWN_TASK";
export const DELETE_OWN_TASK_ERR = "DELETE_OWN_TASK_ERR";

// add task function
export function addTask(data) {
  return (dispatch) => {
    db.collection("tasks")
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        // const Taskdata = docRef.data();
        const dataToStore = { id, ...data };

        dispatch({
          type: ADD_TASK,
          payload: dataToStore,
        });
      })
      .catch(function (error) {
        toast.error("Error occured. Please try again later");
      });
  };
}

// // add paperwork task function
// export function addPaperWorkTask(data) {
//   return dispatch => {
//     db.collection("paperworktask")
//       .add(data)
//       .then(function(docRef) {
//         const id = docRef.id;
//         // const Taskdata = docRef.data();
//         const dataToStore = { id, ...data };
//         console.log("=======doc ref=============================");
//         console.log(dataToStore);
//         console.log("====================================");

//         dispatch({
//           type: PAPERWORK_TASK,
//           payload: dataToStore
//         });
//       })
//       .catch(function(error) {
//         Toast.show({
//           text: "Error occoured Try again!",
//           buttonText: "ok",
//           position: "top",
//           type: "danger"
//         });
//       });
//   };
// }

// Get all task
export function getTask(data) {
  console.log("data", data);

  return (dispatch) => {
    db.collection("tasks")
      .where("uid", "==", data)
      .onSnapshot(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          if (data.DueTime !== "Completed") {
            datatoStore.push({ id, ...data });
          }
          return datatoStore;
        });
        console.log("============datatoStore========================");
        console.log(datatoStore);
        console.log("====================================");

        dispatch({
          type: GET_TASK,
          payload: datatoStore,
        });
      });
  };
}

// update action

export function updateTask(data) {
  return (dispatch) => {
    db.collection("tasks")
      .doc(data.id)
      .set(data)
      .then(function (docRef) {
        dispatch({
          type: UPDATE_TASK,
          payload: data,
        });
        toast.success("successfully updated");
      })
      .catch(function (error) {
        toast.error("Error ocurred! try again later");
        dispatch({
          type: UPDATE_TASK_ERR,
        });
      });
  };
}

// to add completed task
export function completedTask(data) {
  console.log("good! you are at Action file: ", data);
  return (dispatch) => {
    db.collection("tasks")
      .doc(data.id)
      .set(data)
      .then(function (docRef) {
        dispatch({
          type: COMPL_TASK,
          payload: data,
        });
        toast.success("Successfully Completed");
      })
      .catch(function (error) {
        toast.error("Error occured! try again later");

        dispatch({
          type: COMPL_TASK_ERR,
        });
      });
  };
}

// action for delete task

export function deleteTask(id) {
  return (dispatch) => {
    db.collection("tasks")
      .doc(id)
      .delete()
      .then(function () {
        dispatch({
          type: DELETE_TASK,
          payload: id,
        });
        toast.success("Successfully Deleted");
      })

      .catch(function (error) {
        toast.error("Error occured! try again later");

        dispatch({
          type: DELETE_TASK_ERR,
        });
      });
  };
}

// Get all Completed task
export function getCompletedTask(data) {
  return (dispatch) => {
    db.collection("tasks")
      .where("uid", "==", data)
      .onSnapshot(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;

          if (data.DueTime == "Completed") {
            datatoStore.push({ id, ...data });
          }
          return datatoStore;
        });
        dispatch({
          type: GT_COMP_TASK,
          payload: datatoStore,
        });
      });
  };
}

//  add own task function
export function addOwnTask(data) {
  return (dispatch) => {
    db.collection("owntask")
      .add(data)
      .then(function (docRef) {
        const id = docRef.id;
        // const Taskdata = docRef.data();
        const dataToStore = { id, ...data };

        // Toast.show({
        //   text: "Task added successfully",
        //   type: "success",
        //   duration: 3000,
        //   position: "top"
        // });

        dispatch({
          type: ADD_OWN_TASK,
          payload: dataToStore,
        });
      })
      .catch(function (error) {
        // Toast.show({
        //   text: "Error occoured Try again!",
        //   buttonText: "ok",
        //   position: "top",
        //   type: "danger"
        // });
      });
  };
}

export function getEmployerTask(data) {
  return (dispatch) => {
    db.collection("owntask")
      .where("uid", "==", data)

      .get()
      .then(function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const id = doc.id;
          datatoStore.push({ id, ...data });

          return datatoStore;
        });

        dispatch({
          type: GET_EMPLOYER_TASK,
          payload: datatoStore,
        });
      })
      .catch(function (error) {
        // Toast.show({
        //   text: "Error occoured Try again!",
        //   buttonText: "ok",
        //   position: "top",
        //   type: "danger"
        // });
      });
  };
}

// action for delete own task

export function deletOwnTask(id) {
  return (dispatch) => {
    db.collection("owntask")
      .doc(id)
      .delete()
      .then(function () {
        dispatch({
          type: DELETE_OWN_TASK,
          payload: id,
        });
        toast.success("Successfully Deleted");
      })
      .catch(function (error) {
        toast.error("Error occur, try again later");

        dispatch({
          type: DELETE_OWN_TASK_ERR,
        });
      });
  };
}
///////////////////////////////////////////////////////////////////////
///////////// Simple functinos //////////////////
export const addPaperWork = (data) =>
  new Promise(async (resolve, reject) => {
    if (await isExist(data)) {
      db.collection("paperworktask").add(data).then(resolve).catch(reject);
    } else {
      reject("exist");
    }
  });

export const addMultiplePaperWork = (data, emps, tag) => {
  var batch = db.batch();
  let record;
  return new Promise((resolve, reject) => {
    for (let emp of emps) {
      var docRef = db.collection("paperworktask").doc();
      if (tag === "employer") {
        record = {
          ...data,
          // name: emp.name,
          emp_id: emp.uid,
        };
      } else if (tag !== undefined) {
        record = {
          ...data,
          // name: emp.name,
          emp_id: emp.employeeid,
          forward: tag,
        };
      } else {
        record = {
          ...data,
          // name: emp.name,
          emp_id: emp.employeeid,
        };
      }
      batch.set(docRef, record);
    }
    // Commit the batch
    batch
      .commit()
      .then(function () {
        resolve("success");
      })
      .catch((err) => {
        // console.log("ERR*********", err);
        reject(err);
      });
  });
};
//////////// FIND DUPLICATE, Exist object ///////////////////
function isExist(obj) {
  return db
    .collection("paperworktask")
    .where("doc_id", "==", obj.doc_id)
    .where("emp_id", "==", obj.emp_id)
    .get()
    .then((doc) => {
      if (doc.docs.length === 0) {
        return true;
      } else {
        return false;
      }
    });
}

///////////////////////////////
//// Add varified docs
// export function addPaperWorkTask(data, tag, signle) {
//   return (dispatch) => {
//     addPaperWork(data)
//       .then((res) => {
//         toast.success("Varified successfully");
//         dispatch({
//           type: ADD_VERIFIED,
//           payload: data,
//         });
//       })
//       .catch((err) => console.log("ERR", err));
//   };
// }
