import toast from "toastr";
import axios from "axios";
import { admin_url, subscriptionApi } from "../Endpoint";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
// get
export const CHANGE_SUBSCRIPTION = "CHANGE_SUBSCRIPTION";
export const CHANGE_SUBSCRIPTION_ERR = "CHANGE_SUBSCRIPTION_ERR";

export function cancelSubscriptions(data) {
  // " https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/cancelSubscriptions",
  return (dispatch) => {
    axios
      .post(`${admin_url}${subscriptionApi.cancel_subscription}`, data)
      .then((res) => {
        if (res.data == "success") {
          console.log("res", res.data);
          toast.success("Succefully Sucscription Cancel");
          dispatch({
            type: CANCEL_SUBSCRIPTION,
          });
        } else {
          console.log("else", res.data);
          toast.error("Error occured. Please try again later");
          dispatch({
            type: CANCEL_SUBSCRIPTION,
          });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error("err", err);
        toast.error("Error occured. Please try again later");
        dispatch({
          type: CANCEL_SUBSCRIPTION,
        });
      });
  };
}

export function changeSubscriptions(data) {
  // " https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/changeSubscriptions",
  return (dispatch) => {
    axios
      .post(`${admin_url}${subscriptionApi.change_subscription}`, data)
      .then((res) => {
        if (res.data == "successfully Changed") {
          toast.success("Succefully changed Sucscription");
          dispatch({
            type: CHANGE_SUBSCRIPTION,
          });
        } else {
          console.log("else", res.data);
          toast.error("Error occured. Please try again later");
          dispatch({
            type: CHANGE_SUBSCRIPTION,
          });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error("err", err);
        toast.error("Error occured. Please try again later");
        dispatch({
          type: CHANGE_SUBSCRIPTION,
        });
      });
  };
}

export function applyNewSubscriptions(data) {
  return (dispatch) => {
    axios
      .post(`${admin_url}${subscriptionApi.apply_newSubscription}`, data)
      .then((res) => {
        console.log("res", res.data);
        if (res.data == "successfully Changed") {
          toast.success("Succefully changed Sucscription");
          dispatch({
            type: CHANGE_SUBSCRIPTION,
          });
        } else {
          console.log("else", res.data);
          toast.error("Error occured. Please try again later");
          dispatch({
            type: CHANGE_SUBSCRIPTION,
          });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error("err", err);
        toast.error("Error occured. Please try again later");
        dispatch({
          type: CHANGE_SUBSCRIPTION,
        });
      });
  };
}
