import React from 'react';
import CustomScrollbars from "util/CustomScrollbars";


export default  (props) => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <span>All States</span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                props.width >= 1200
                  ? "calc(100vh - 200px)"
                  : "calc(100vh - 80px)"
            }}
          >
            <div className="module-add-task">
              <div className="form-group">
                  <input
                    className="form-control form-control-md"
                    id="searchState"
                    type="text"
                    placeholder="Search State"
                    name="search"
                    // onChange={onChangeHandler}
                  />
                </div>
            </div>

            <ul className="module-nav">
              <li>
                <span
                  className={`jr-link active`}
                >
                  <span>Alaska</span>
                </span>
              </li>
              <li>
                <span
                  className={`jr-link`}
                >
                  <span>Corona</span>
                </span>
              </li>
            </ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };


