
import {withStyles} from "@material-ui/core";


export const MaterialGrid = withStyles({
    // @global is handled by jss-plugin-global.
    '@global': {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      // '.MuiGrid-root': {
      //   paddingTop: `0px`,
      //   paddingBottom: `0px`,
      //   paddingRight: `0px`,
      //   paddingLeft: `0px`,
      //   margin: `0px`
      // },
      // '.MuiGrid-item': {
      //   padding: `0px !important`,
      //   margin: `0px !important`
      // }
     
    },
  })(() => null);