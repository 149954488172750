import {
  LOGINERR,
  LOGIN,
  GETUSER,
  LOGOUT,
  GETUSERERR,
  CHANGE_ADDRESS,
  CHANGE_ADDRESS_ERR,
} from "../../actions/Auth";

import {
  SAVEEMPLOYER,
  GETEMPLOYER,
  ALL_TICKETS_GET_SUCCESSFULLY,
  ALL_TICKETS_GET_FAILED,
  ALL_TICKETS_GET_START,
} from "../../actions/userActions";

var initialState = {
  isLoading: "nill",
  hasErrored: false,
  userStatus: true,
  LogoutDone: "notdone",
  employer: {},
  user: {},
  getuserErr: "sadf",
  adressStatus: "not done",
  loader: new Date(),
  saveEmployerStatus: "not done",
  getEmployerStatus: "not done",
  loading: new Date(),
  allTickets: [],
  ticketsStatus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        userStatus: true,
        LogoutDone: "notdone",
        user: action.payload,
        isLoading: "move",
      };
    }
    case ALL_TICKETS_GET_START: {
      return {
        ...state,
        ticketsStatus: true,
      };
    }
    case ALL_TICKETS_GET_FAILED: {
      return {
        ...state,
        ticketsStatus: false,
      };
    }
    case ALL_TICKETS_GET_SUCCESSFULLY: {
      return {
        ...state,
        allTickets: action.payload,
        ticketsStatus: false,
      };
    }
    case GETUSER: {
      return {
        ...state,
        userStatus: true,
        LogoutDone: "notdone",
        user: action.payload,
      };
    }

    case GETUSERERR: {
      return {
        ...state,
        getuserErr: new Date(),
      };
    }

    case SAVEEMPLOYER: {
      console.log("Employer in Save Reducer: ", action.data);
      return {
        ...state,
        employer: action.data,
        saveEmployerStatus: "done",
        loading: new Date(),
      };
    }

    case GETEMPLOYER: {
      console.log("Employer in Get Reducer: ", action.data);
      return {
        ...state,
        employer: action.data,
        getEmployerStatus: "done",
        loading: new Date(),
      };
    }

    case LOGOUT: {
      return {
        ...state,
        isLoading: "ssadf",
        LogoutDone: "done",
        userStatus: false,
        user: {},
      };
    }
    case CHANGE_ADDRESS: {
      let user = state.user;
      let newAddress = action.payload;
      let newObj = { ...user, ...newAddress };
      console.log("==========newObj from reducer==========================");
      console.log(newObj);
      console.log("====================================");

      return {
        ...state,
        adressStatus: "done",
        user: newObj,
        loader: new Date(),
      };
    }

    case CHANGE_ADDRESS_ERR: {
      return {
        ...state,
        adressStatus: "error",
        loader: new Date(),
      };
    }

    case LOGINERR:
      return { ...state, hasErrored: action.payload };
    case "ITEMS_FETCH_DATA_SUCCESS":
      return { ...state, items: action.items };
    default:
      return state;
  }
}
