import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  CHANGE_STATUS
} from "constants/ActionTypes";

import { GETUSER, GETUSERERR, LOGIN, LOGINERR, LOGOUT } from "../../actions/Auth";

var initialState = {
  isLoading: "nill",
  hasErrored: false,
  userStatus: false,
  LogoutDone: "notdone",
  user: {},
  getuserErr: "sadf",
  adressStatus: "not done",
  loader: new Date(),
  alertMessage: "",
  showMessage: false,
  initURL: "",
  userType: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        userStatus: true,
        LogoutDone: "notdone",
        user: action.payload,
        isLoading: "move",
        loader: new Date(),
        userType: action.payload.type
      };
    }
    case CHANGE_STATUS: {
      return{
        ...state,
        userType: "reviewer",
        loader: new Date(),

      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }

    case GETUSER: {
      console.log("=========get payload===========================");
      console.log(action.payload);
      console.log("====================================");
      return {
        ...state,
        userStatus: true,
        LogoutDone: "notdone",
        user: action.payload,
        userType: action.payload.type
      };
    }

    case GETUSERERR: {
      return {
        ...state,
        userStatus: false,
        getuserErr: new Date()
      };
    }

    case LOGOUT: {
      return {
        ...state,
        isLoading: "ssadf",
        LogoutDone: "done",
        userStatus: false,
        user: {},
        userType: ""
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false
      };
    }
    //   case CHANGE_ADDRESS: {
    //     let user = state.user;
    //     let newAddress = action.payload;
    //     let newObj = { ...user, ...newAddress };
    //     console.log("==========newObj from reducer==========================");
    //     console.log(newObj);
    //     console.log("====================================");

    //     return {
    //       ...state,
    //       adressStatus: "done",
    //       user: newObj,
    //       loader: new Date()
    //     };
    //   }

    //   case CHANGE_ADDRESS_ERR: {
    //     return {
    //       ...state,
    //       adressStatus: "error",
    //       loader: new Date(),
    //     };
    //   }

    case LOGINERR:
      return { ...state, hasErrored: action.payload, loader: new Date() };

    default:
      return state;
  }
}
