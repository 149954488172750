import {
    GET_TERMS_CONDITIONS,
    GET_TERMS_CONDITIONS_ERR,
    GET_PRIVACY_POLICY,
    GET_PRIVACY_POLICY_ERR,
    ADD_PRIVACY_POLICY,
    ADD_PRIVACY_POLICY_ERR,
    ADD_TERMS_CONDITIONS,
    ADD_TERMS_CONDITIONS_ERR
  } from "constants/ActionTypes";

  const initialState = {
    termsConditions: {},
    privacyPolicy: {},
    getTermsStatus: "not done",
    getPrivacyStatus: "not done",
    addTermsStatus: "not done",
    addPrivacyStatus: "not done",
    loader: new Date(),
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      
        case ADD_PRIVACY_POLICY: {
            return {
                ...state,
                privacyPolicy: action.payload,
                addPrivacyStatus: "done",
                getTermsStatus: "not done",
                getPrivacyStatus: "not done",
                addTermsStatus: "not done",
                loader: new Date()
            };
        }

        case ADD_PRIVACY_POLICY_ERR: {
            return {
                ...state,
                addPrivacyStatus: "error",
                getTermsStatus: "not done",
                getPrivacyStatus: "not done",
                addTermsStatus: "not done",
                loader: new Date()
            };
        }

        case ADD_TERMS_CONDITIONS: {
            return {
                ...state,
                termsConditions: action.payload,
                addTermsStatus: "done",
                addPrivacyStatus: "not done",
                getTermsStatus: "not done",
                getPrivacyStatus: "not done",
                loader: new Date()
            };
        }

        case ADD_TERMS_CONDITIONS_ERR: {
            return {
                ...state,
                addTermsStatus: "error",
                getTermsStatus: "not done",
                getPrivacyStatus: "not done",
                addPrivacyStatus: "not done",
                loader: new Date()
            };
        }

        case GET_TERMS_CONDITIONS: {
            return {
                ...state,
                termsConditions: action.payload,
                getTermsStatus: "done",
                addTermsStatus: "not done",
                addPrivacyStatus: "not done",
                getPrivacyStatus: "not done",
                loader: new Date()
            };
        }

        case GET_TERMS_CONDITIONS_ERR: {
            return {
                ...state,
                getTermsStatus: "error",
                addTermsStatus: "not done",
                getPrivacyStatus: "not done",
                addPrivacyStatus: "not done",
                loader: new Date()
            };
        }
      
        case GET_PRIVACY_POLICY: {
            return {
                ...state,
                privacyPolicy: action.payload,
                getPrivacyStatus: "done",
                getTermsStatus: "not done",
                addPrivacyStatus: "not done",
                addTermsStatus: "not done",
                loader: new Date()
            };
        }

        case GET_PRIVACY_POLICY_ERR: {
            return {
                ...state,
                getPrivacyStatus: "error",
                addPrivacyStatus: "not done",
                getTermsStatus: "not done",
                addTermsStatus: "not done",
                loader: new Date()
            };
        }

        default:
            return state;
    }
  };