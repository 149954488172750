import React from 'react';
const Footer = () => {
    return (
      <footer style={{textAlign: "center"}} className="app-footer">
        <p style={{margin: "auto"}}>Copyright helpara &copy; {new Date().getFullYear()}</p>
      </footer>
    );
  }
;

export default Footer;
