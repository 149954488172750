import {
  ADD_NEW_DOCUMENTS,
  ADD_NEW_DOCUMENTS_ERR,
  GET_DOCUMENTS,
  GET_DOCUMENTS_ERR,
  GET_UNVERIFIEDDOCUMENTS_ERR,
  GET_UNVERIFIEDDOCUMENTS,
  GET_AUTOPOPULATE_ERR,
  GET_AUTOPOPULATE,
  GET_TEMPLATESCHEMA,
  GET_TEMPLATESCHEMA_ERR,
  GET_SINGLEEMPLOYEE,
  ADD_NEW_UNVERIFIED_DOCUMENTS,
  GET_SINGLEEMPLOYEER,
  GET_UNVERIFIED_STATE_DOC,
  GET_STATE_DOCS,
  GET_JOT_FORM,
  ADD_VERIFIED,
} from "../../actions/NewSystemDocuments";

var initialState = {
  systemdocuments: [],
  jotforms: [],
  unverifieddocuments: [],
  unverfied_loader: "some",
  isLoading: "null",
  loader: "null",
  documentLoader: "null",
  autopopulate_loader: "safd",
  autopopulate: {},
  employee: {},
  employer: {},
  templateSchema: {
    properties: {},
  },
  schema_loader: "sfd",
  add_verified_doc_loader: "FSA",
  stateUnverifiedDoc: [],
  stateVerifiedDocs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_DOCUMENTS: {
      return {
        ...state,
        isLoading: "move",
        loader: new Date(),
      };
    }

    case ADD_NEW_DOCUMENTS_ERR: {
      return {
        ...state,
        isLoading: "error",
        loader: new Date(),
      };
    }

    case GET_DOCUMENTS: {
      return {
        ...state,
        systemdocuments: action.payload,
        documentLoader: new Date(),
      };
    }
    ///////// JOTFORM //////////////
    case GET_JOT_FORM: {
      return {
        ...state,
        jotforms: action.payload,
        documentLoader: new Date(),
      };
    }
    case ADD_VERIFIED: {
      let filter = state.jotforms.filter((el) => el.id !== action.payload.id);
      return {
        ...state,
        jotforms: filter,
        documentLoader: new Date(),
      };
    }
    //////////////////
    case GET_DOCUMENTS_ERR: {
      return {
        ...state,
        documentLoader: new Date(),
      };
    }

    case GET_UNVERIFIEDDOCUMENTS: {
      return {
        ...state,
        unverifieddocuments: action.payload,
        unverfied_loader: new Date(),
      };
    }

    case GET_UNVERIFIEDDOCUMENTS_ERR: {
      return {
        ...state,
        unverfied_loader: new Date(),
      };
    }

    case GET_AUTOPOPULATE: {
      return {
        ...state,
        autopopulate: action.payload,
        autopopulate_loader: new Date(),
      };
    }

    case GET_AUTOPOPULATE_ERR: {
      return {
        ...state,
        autopopulate_loader: new Date(),
      };
    }

    case GET_TEMPLATESCHEMA: {
      return {
        ...state,
        templateSchema: action.payload,
        schema_loader: new Date(),
      };
    }

    case GET_TEMPLATESCHEMA_ERR: {
      return {
        ...state,
        schema_loader: new Date(),
      };
    }

    case GET_SINGLEEMPLOYEE: {
      return {
        ...state,
        employee: action.payload,
      };
    }
    case GET_SINGLEEMPLOYEER: {
      return {
        ...state,
        employer: action.payload,
      };
    }

    case ADD_NEW_UNVERIFIED_DOCUMENTS: {
      return {
        ...state,
        add_verified_doc_loader: new Date(),
      };
    }
    case GET_UNVERIFIED_STATE_DOC: {
      return {
        ...state,
        stateUnverifiedDoc: action.payload,
      };
    }
    case GET_STATE_DOCS:
      return {
        ...state,
        stateVerifiedDocs: action.payload,
      };

    default:
      return state;
  }
}
