import {
  GET_SHOPCARD,
  GET_SHOPCARD_ERR,
  GET_SLC_SHOPCARD,
  GET_SLC_SHOPCARD_ERR,
  UPDATE_SHOPCARD,
  UPDATE_SHOPCARD_ERR
} from "../../actions/rewardAction";

var initialState = {
  loader: "null",
  getShopCardStatus: "not done",
  shopCards: [],
  selectedShopCards: [],
  getSelectedStatus: "not done",
  updateRewardStatus: "not done"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SHOPCARD: {
      let selectedShopCards = state.selectedShopCards;

      let shopCards = [];
      var countList = action.payload.reduce(function(p, c) {
        p[c.merchant_id] = (p[c.merchant_id] || 0) + 1;
        return p;
      }, {});

      Object.keys(countList).map(merch => {
        let shopCard = {};
        let cards = [];
        let i = 0;
        action.payload.map(card => {
          if (card.merchant_id === merch) {
            cards.push(card);
            if (i === 0) {
              shopCard["merchant_id"] = card.merchant_id;
              shopCard["merchant_name"] = card.merchant_name;
              shopCard["long_description"] = card.long_description;
              shopCard["merchant_icon_image_url_hd"] =
                card.merchant_icon_image_url_hd;
            }
            i++;
          }
        });
        shopCard["shopCards"] = cards;
        shopCards.push(shopCard);
      });

      function comparer(otherArray) {
        return function(current) {
          return (
            otherArray.filter(function(other) {
              return other.merchant_id == current.merchant_id;
            }).length == 0
          );
        };
      }

      var onlyInA = shopCards.filter(comparer(selectedShopCards));
      onlyInA.map(c => {
        c["selected"] = false;
      });
      selectedShopCards.map(c => {
        c["selected"] = true;
      });
      let newCards = selectedShopCards.concat(onlyInA);

      return {
        ...state,
        loader: new Date(),
        getShopCardStatus: "done",
        shopCards: newCards
      };
    }

    case GET_SHOPCARD_ERR: {
      return {
        ...state,
        loader: new Date(),
        getShopCardStatus: "error"
      };
    }
    case GET_SLC_SHOPCARD: {
      return {
        ...state,
        loader: new Date(),
        getSelectedStatus: "done",
        selectedShopCards: action.payload
      };
    }

    case GET_SLC_SHOPCARD_ERR: {
      return {
        ...state,
        loader: new Date(),
        getSelectedStatus: "error"
      };
    }
    case UPDATE_SHOPCARD: {
      console.log("UPDATE_SHOPCARD reducer");
      
      return {
        ...state,
        loader: new Date(),
        updateRewardStatus: "done"
      };
    }

    case UPDATE_SHOPCARD_ERR: {
      return {
        ...state,
        loader: new Date(),
        updateRewardStatus: "error"
      };
    }
    case "SET_DEFAULT_UPDATE": {
      return {
        ...state,
        loader: new Date(),
        updateRewardStatus: "not done"
      };
    }

    default:
      return state;
  }
}
