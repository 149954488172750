import {
    GET_ALL_COUPONS,
    GET_ALL_COUPONS_ERR,
    UPDATE_COUPON,
    UPDATE_COUPON_ERR,
    CREATE_COUPON,
    CREATE_COUPON_ERR
  } from "constants/ActionTypes";
  
  var initialState = {
    loader: new Date(),
    coupons: [],
    getAllCouponsStatus: "not done",
    updateCouponStatus: "not done",
    createCouponStatus: "not done",
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_ALL_COUPONS:
        return {
          ...state,
          loader: new Date(),
          coupons: action.payload,
          getAllCouponsStatus: "done"
        };
      case GET_ALL_COUPONS_ERR:
        return {
          ...state,
          loader: new Date(),
          getAllCouponsStatus: "error"
        };
      case UPDATE_COUPON:
        const coupon = action.payload;
        let updatedCoupons = state.coupons.filter(item => item.id != coupon.id);
        updatedCoupons.push(coupon);
        return {
          ...state,
          loader: new Date(),
          coupons: updatedCoupons,
          updateCouponStatus: "done"
        };
      case UPDATE_COUPON_ERR:
        return {
          ...state,
          loader: new Date(),
          updateCouponStatus: "error"
        };
      case CREATE_COUPON:
        let prevData = state.coupons;
        let updatedData = prevData.push(action.payload);
        return {
          ...state,
          loader: new Date(),
          coupons: updatedData,
          createCouponStatus: "done"
        };
      case CREATE_COUPON_ERR:
        return {
          ...state,
          loader: new Date(),
          createCouponStatus: "error"
        };
  
      default:
        return state;
    }
  }
  