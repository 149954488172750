import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { confirmAlert } from "react-confirm-alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { BeatLoader } from "react-spinners";

export default class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  cancelSub(name, stripeid, docid) {
    let data = {
      subscription: stripeid,
      docid: docid
    };

    confirmAlert({
      title: "Are you sure to cancel " + name + "'s subscription",
      message: name + " will not login any more",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.cancelSubscriptions(data)
        },
        {
          label: "No",
          onClick: () => console.log("click no")
        }
      ]
    });
  }

  render() {
    const { styleName, data } = this.props;
    const { name, chosenDate, stateName } = data;
    return (
      <div className={`user-list d-flex flex-row  ${styleName}`}>
        <div className="description">
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <h4 style={{ display: "inline", fontWeight: "bold" }}>{name}</h4>
            </Grid>
          </Grid>
          <h6 style={{ color: `#7d7171` }}>
            Joining From {moment(chosenDate).format("MMM/DD/YYYY")}
          </h6>
          <h6 style={{ color: `#7d7171` }}>State: {stateName}</h6>
          <ul className="list-inline d-sm-flex flex-sm-row jr-mbtn-list">
            <li>
              <Button color="primary">View PDF</Button>
            </li>
            <li>
              <Button
                color="secondary"
                // onClick={() => this.cancelSub(name, stripesubscription, id)}
              >
                Approve
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
