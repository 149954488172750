import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

const MailListItem = ({ mail, onMailSelect, onMailChecked, onStartSelect }) => {
  return (
    <div className="module-list-item mail-cell empItem" >
      <Checkbox
        color="primary"
        checked={mail.selected}
        onClick={event => {
          event.stopPropagation();
          onMailChecked(mail);
        }}
        value="SelectMail"
      />

      <div
        className="module-list-info"
        onClick={() => {
          onMailSelect(mail);
        }}
      >
        <div className="module-list-content">
          <div className="mail-user-info">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <div className="headingParent">
                <span className="sender-name text-dark">Employee Name</span>

                <span className="toolbar-separator" />

                <span className="d-inline-block text-dark">
                  State: State
                </span>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} alignItems="flex-end" justify="flex-end">
                <div className="time lastSend">Last Sent: 24/12/2020</div>
              </Grid>
            </Grid>
          </div>

          <div className="message mt-2">
            <p>Update PDF: 10/12/2020</p>
          </div>
          <div className="message mb-2 ">
            <p>Started Job at: 10/09/2019</p>
          </div>

          <div className="labels ">
            <div className={`badge text-white bg-green darken-1 badgeDoc`}>View PDF</div>
            <div className={`badge text-white bg-amber darken-2 badgeDoc`}>Approve</div>
            <div className={`badge text-white bg-light-blue darken-2 badgeDoc`}>
              Generate New PDF
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailListItem;
