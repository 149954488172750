import React, { Component } from "react";
import { Provider } from "react-redux";

import configureStore, { history } from "./store";
import "./firebase/firebase";
import App from "./checkUser";
import {MaterialGrid} from "./util/materialGrid";

export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <MaterialGrid />
    <App />
  </Provider>
);

export default MainApp;
