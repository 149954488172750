import React, { useState, useEffect } from "react";
import "./styles.css";
import TicketAccordians from "./ticketAccordians";
import ContainerHeader from "../../../../components/ContainerHeader";
//components/ContainerHeader
const TicketSystem = ({ match }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const onTextChangeHandler = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      className="animated slideInUpTiny animation-duration-3"
      style={{ margin: "auto", width: "90%", position: "relative", top: 10 }}
    >
      <ContainerHeader
        title="Tickets"
        match={match}
        searchQuery={searchQuery}
        onTextChangeHandler={onTextChangeHandler}
      />
      <div className="row mb-md-3">
        <TicketAccordians searchQuery={searchQuery} />
      </div>
    </div>
  );
};

export default TicketSystem;
