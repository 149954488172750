import { db } from "../../firebase/firebase";
import toastr from "toastr";
import Endpoint from "../../Endpoint";
import axios from "axios";
export const GET_ALL_DOCS = "GET_ALL_DOCS";

export const getAll = (cb) => {
  return (dispatch) => {
    db.collection("stateDocuments")
      .where("status", "==", "verified")
      .onSnapshot(
        (querySnapshot) => {
          let dataToStore = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            const id = doc.id;
            dataToStore.push({ id, ...data });
          });
          dispatch({
            type: GET_ALL_DOCS,
            payload: dataToStore,
          });
          cb("success");
        },
        (err) => {
          console.log("err getting employee", err);
          toastr.error("Error Occurred. Try again later");
          cb("error", err);
        }
      );
  };
};

export const generatePDF = (sendToServer, cb) => {
  return (dispatch) => {
    axios
      .post(`${Endpoint}/formapi/superAdmingeneratePDF`, sendToServer)
      .then((res) => {
        if (res.data.status === "success") {
          cb("success", res.data);
        } else {
          cb("error");

          toastr.error("Please try again!");
        }
      })
      .catch((err) => {
        cb("error");
        toastr.error("Error has been occurred!");
      });
  };
};
