import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import BasicTable from "./BasicTable";
import { getEmployers } from "../../../../../../actions/reviewer_actions/employerActions";
import { getTask } from "../../../../../../actions/reviewer_actions/TasksActions";
import { addLog } from "../../../../../../actions/reviewer_actions/logActions";
import SearchBar from "../SearchBar/SearchBar";
import Footer from "../../../../../../components/Footer";
import {Link} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';


class EmployeeTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "true",
      DueDate: "",
      loader: true,
      dataLength: true,
      data: [], 
      searchQuery: ''
    };
  }
  
 componentDidMount() {
  this.props.addLog({
    reviewer: this.props.reviewer.name,
    page: "All Employers",
    visited: true,
    timestamp: new Date(),
  });
 }

  componentWillReceiveProps = nextProps => {
    if(nextProps.move === "move") {
      this.setState({
        loader: false,
        data: nextProps.employers
      })
    }

    if (nextProps.loader === "false") {
      this.setState({
        loader: false,
        data: nextProps.employers
      });
    }
  };

  onSearchChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }
  
  filterMessages=(query)=>{
    this.setState({
      searchQuery : query,
    })
  }

  render() {
    const { data } = this.state;

    console.log(data);
    return (
      <div className="app-container">
        <div className="app-main-container">
          <div className="app-header">
            <AppBar className="app-main-header app-main-header-top">
              <Toolbar className="app-toolbar">
                <Link className="app-logo mr-2 d-none d-sm-block" to="/">
                  <img src={require("assets/unhr-images/Logo-Extra.png")} alt="Jambo" title="Jambo"/>
                </Link>
              </Toolbar>
            </AppBar>
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Container>
                <Row style={{marginTop: 30}}>
                  <SearchBar title='List Of Employers' filter={ this.filterMessages } placeholder="Search by Name, Title, Date" />
                </Row>
                <Row>
                  <BasicTable searchQuery ={ this.state.searchQuery } />
                </Row>
              </Container>
            </div>
            <Footer/>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log("STATETOPROPS:", state)
  return {
  employers: state.employerReducer.employers,
  user: state.userReducer.user,
  reviewer: state.auth.user,
  loader: state.TaskReducer.loader
  }
};

export default connect(
    mapStateToProps,
    {
      getTask,
      getEmployers,
      addLog
    }
  )(EmployeeTask);








