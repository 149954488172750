import React, { Component } from "react";
import { Datatable } from "@o2xp/react-datatable";

import {
  FreeBreakfast as CoffeeIcon,
  CallSplit as CallSplitIcon,
} from "@material-ui/icons";
function App(props) {
  const options = {
    text: {
      noRow: "There is no Document Verified",
    },
    title: "State Documents",
    dimensions: {
      datatable: {
        width: "90%",
        height: "400px",
      },
      row: {
        height: "48px",
      },
    },
    keyColumn: "doc_name",
    font: "Arial",
    data: {
      columns: [
        {
          id: "doc_name",
          label: "Document Name",
          colSize: "150px",
          editable: false,
        },
        {
          id: "state",
          label: "State",
          colSize: "100px",
          editable: true,
          dataType: "select",
          inputType: "select",
          values: ["green", "blue", "brown"],
        },
        {
          id: "schedule",
          label: "Schedule (months)",
          colSize: "100px",
          editable: true,
          dataType: "number",
          inputType: "number",
        },
        {
          id: "sendingPeriod",
          label: "Sending Period(days)",
          colSize: "80px",
          editable: true,
          dataType: "number",
        },
      ],
      rows: props.stateVerifiedDocs,
    },
    features: {
      canEdit: true,
      canDelete: true,

      canSearch: true,
      canOrderColumns: true,

      rowsPerPage: {
        available: [10, 25, 50, 100],
        selected: 50,
      },
    },
  };

  const actionsRow = ({ type, payload }) => {
    // console.log("type", type);
    // console.log("payload", payload);
    if (type === "save") {
      let data = {
        schedule: payload.schedule,
        state: payload.state,
        sendingPeriod: payload.sendingPeriod,
        doc_id: payload.doc_id,
      };
      props.updateDoc(data);
    } else if ((type = "delete")) {
      props.deleteStateDoc(payload.doc_id);
    }
  };

  return <Datatable options={options} actions={actionsRow} />;
}

export default App;
