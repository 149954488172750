import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import mails from "../data/mails";
import folders from "../data/folders";
import filters from "../data/filters";
import labels from "../data/labels";
import options from "../data/options";
import MailList from "components/mail/MailList";
import ComposeMail from "components/mail/Compose";
import AppModuleHeader from "components/AppModuleHeader/index";
import MailDetail from "components/mail/MailDetail/index";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import {
  getShopCards,
  collectionUpdate,
  setDefaultUpdate
} from "actions/rewardAction";
import toastr from "toastr";
import { PulseLoader } from "react-spinners";

class Mail extends Component {
  MailSideBar = () => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            {/* <i className="zmdi zmdi-card-giftcard" /> */}
            <span>Shop Cards</span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                this.props.width >= 1200
                  ? "calc(100vh - 200px)"
                  : "calc(100vh - 80px)"
            }}
          >
            <ul className="module-nav">{this.getNavFolders()}</ul>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  getNavFolders = () => {
    return folders.map((folder, index) => (
      <li
        key={index}
        onClick={() => {
          const filterMails = this.state.allShopCards.filter(
            mail => mail.folder === folder.id
          );
          this.setState({
            selectedFolder: folder.id,
            noContentFoundMessage: "No mail found in selected folder",
            currentMail: null,

            folderMails: filterMails
          });
        }}
      >
        <span
          className={`jr-link ${
            this.state.selectedFolder === folder.id ? "active" : ""
          }`}
        >
          <i className={`zmdi zmdi-${folder.icon}`} />
          <span>{folder.title}</span>
        </span>
      </li>
    ));
  };

  handleRequestClose = () => {
    this.setState({
      composeMail: false,
      showMessage: false,
      folderMenuState: false,
      labelMenuState: false,
      optionMenuState: false
    });
  };

  // searchMail = searchText => {
  //   if (searchText === "") {
  //     this.setState({
  //       folderMails: this.state.allMail.filter(mail => !mail.deleted)
  //     });
  //   } else {
  //     const searchMails = this.state.allMail.filter(
  //       mail =>
  //         !mail.deleted &&
  //         mail.subject.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  //     );
  //     this.setState({
  //       folderMails: searchMails
  //     });
  //   }
  // };

  displayMail = (allShopCards, noContentFoundMessage) => {
    return (
      <div className="module-box-column">
        {allShopCards.length == 0 ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height:
                this.props.width >= 1200
                  ? "calc(100vh - 265px)"
                  : "calc(100vh - 245px)"
            }}
          >
            {noContentFoundMessage}
          </div>
        ) : (
          <MailList
            mails={allShopCards}
            // onMailSelect={this.onMailSelect.bind(this)}
            width={this.props.width}
            onMailChecked={this.onMailChecked.bind(this)}
          />
        )}
      </div>
    );
  };

  constructor() {
    super();
    this.state = {
      searchMail: "",
      noContentFoundMessage: "No Shop Card Found",
      alertMessage: "",
      showMessage: false,
      drawerState: false,
      optionName: "None",
      anchorEl: null,

      loader: true,
      currentMail: null,
      user: {
        name: "Robert Johnson",
        email: "robert@example.com",
        avatar: require("assets/images/userAvatar/domnic-harris.jpg")
      },
      selectedMails: 0,
      selectedFolder: 0,
      composeMail: false,
      labelMenuState: false,
      folderMenuState: false,
      optionMenuState: false,
      allShopCards: [],
      selectedShopCards: [],
      selectedStore: [],
      updateLoader: false,
      changesEnable: false
    };
  }

  componentDidMount() {
    this.props.getShopCards();
  }

  onMailChecked(data) {
    console.log("length", this.props.selectedCard);

    if (data.selected) {
      let remainingSelectedCard = this.state.selectedShopCards.filter(
        item => item.merchant_id !== data.merchant_id
      );
      let unSelect = this.state.allShopCards;
      unSelect.map(item => {
        if (item.merchant_id === data.merchant_id) {
          item.selected = false;
        }
      });
      console.log("unSelect", unSelect);

      this.setState({
        allShopCards: unSelect,
        selectedShopCards: remainingSelectedCard,
        changesEnable: true
      });
    } else {
      if (this.state.selectedShopCards.length >= 4) {
        toastr.error("Only four companies can be selected");
      } else {
        let addingCard = this.state.selectedShopCards;
        addingCard.push(data);
        let select = this.state.allShopCards;
        select.map(item => {
          if (item.merchant_id === data.merchant_id) {
            item.selected = true;
          }
        });
        console.log("select", addingCard);

        this.setState({
          allShopCards: select,
          selectedShopCards: addingCard,
          changesEnable: true
        });
      }
    }
  }

  removeLabel(mail, label) {
    mail.labels.splice(mail.labels.indexOf(label), 1);
    if (
      this.state.currentMail !== null &&
      mail.id === this.state.currentMail.id
    ) {
      this.setState({
        currentMail: { ...mail, labels: mail.labels }
      });
    }
    return mail.labels;
  }

  onStartSelect(data) {
    data.starred = !data.starred;
    this.setState({
      alertMessage: data.starred ? "Mail Mark as Star" : "Mail Remove as Star",
      showMessage: true,
      folderMails: this.state.folderMails.map(mail =>
        mail.id === data.id ? data : mail
      )
    });
  }

  addLabel(mail, label) {
    if (
      this.state.currentMail !== null &&
      mail.id === this.state.currentMail.id
    ) {
      this.setState({
        currentMail: { ...mail, labels: mail.labels.concat(label) }
      });
    }
    return mail.labels.concat(label);
  }

  updateSearch = evt => {
    this.setState({
      searchMail: evt.target.value
    });
    // this.searchMail(evt.target.value);
  };

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }
  componentWillReceiveProps(nextProps) {
    console.log("selectedCard nextpros", nextProps.selectedCard);

    if (nextProps.getShopCardStatus === "done") {
      console.log("data in derrived state");
      this.setState({
        allShopCards: nextProps.shopCards,
        loader: false,
        selectedShopCards: nextProps.selectedCard,
        selectedStore: nextProps.selectedCard
      });
    } else if (nextProps.getShopCardStatus === "error") {
      this.setState({
        loader: false
      });
    }
    if (
      nextProps.updateRewardStatus === "done" ||
      nextProps.updateRewardStatus === "error"
    ) {
      this.setState({
        updateLoader: false,
        changesEnable: false
      });
      this.props.setDefaultUpdate();
    }
  }
  updateCollection = () => {
    this.setState({
      updateLoader: true
    });
    console.log("selectedCard", this.state.selectedStore);

    this.props.collectionUpdate(
      this.state.selectedShopCards,
      this.state.selectedStore
    );
  };

  moduleHeader = () => {
    return (
      <div
        className="search-bar right-side-icon bg-transparent"
        style={{
          display: `flex`,
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <div className="form-group" style={{ width: `50%` }}>
          <input
            className="form-control border-0"
            type="search"
            placeholder="Search Shop Card"
            onChange={this.updateSearch}
            value={this.state.searchMail}
          />
          <button className="search-icon">
            <i className="zmdi zmdi-search zmdi-hc-lg" />
          </button>
        </div>
        {this.state.changesEnable && (
          <div style={{ display: "inline" }}>
            {this.state.updateLoader ? (
              <Button color="success" disabled>
                <PulseLoader color={"#123abc"} size={12} />
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={this.updateCollection}
              >
                Save Changes
              </Button>
            )}
          </div>
        )}
      </div>
    );
  };

  searchForShopCard = searchQuery => {
    return function(data) {      
      if(data) {
          return (
              (data.merchant_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
              !searchQuery
          );
      } else {
          return;
      }
    };
  };

  render() {
    const {
      selectedMails,
      loader,
      currentMail,
      folderMails,
      allShopCards,
      alertMessage,
      showMessage,
      selectedFolder,
      selectedShopCards,
      noContentFoundMessage
    } = this.state;
    console.log("Loader-----", loader);
    return (
      <div className="app-wrapper">
        <div className="animated slideInUpTiny animation-duration-3">
          <div className="app-module">
            <div className="d-block d-xl-none">
              <Drawer
                open={this.state.drawerState}
                onClose={this.onToggleDrawer.bind(this)}
              >
                {this.MailSideBar()}
              </Drawer>
            </div>
            <div className="app-module-sidenav d-none d-xl-flex">
              {this.MailSideBar()}
            </div>

            <div className="module-box">
              <div className="module-box-header">
                <IconButton
                  className="drawer-btn d-block d-xl-none"
                  aria-label="Menu"
                  onClick={this.onToggleDrawer.bind(this)}
                >
                  <i className="zmdi zmdi-menu" />
                </IconButton>
                {this.moduleHeader()}
              </div>

              <div className="module-box-content">
                {selectedFolder === 0 ?
                  loader ? (
                    <div
                      className="loader-view"
                      style={{
                        height:
                          this.props.width >= 1200
                            ? "calc(100vh - 265px)"
                            : "calc(100vh - 245px)"
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    this.displayMail(allShopCards.filter(this.searchForShopCard(this.state.searchMail)), noContentFoundMessage)
                  )
                :
                  loader ? (
                    <div
                      className="loader-view"
                      style={{
                        height:
                          this.props.width >= 1200
                            ? "calc(100vh - 265px)"
                            : "calc(100vh - 245px)"
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    this.displayMail(selectedShopCards.filter(this.searchForShopCard(this.state.searchMail)), noContentFoundMessage)
                  )}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showMessage}
            autoHideDuration={3000}
            onClose={this.handleRequestClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{alertMessage}</span>}
          />
        </div>
      </div>
    );
  }
}

// const mapStateToProps = ({ settings }) => {
//   const { width } = settings;
//   return { width };
// };
const mapStateToProps = state => {
  return {
    width: state.settings.width,
    loading: state.RewardReducer.loader,
    getShopCardStatus: state.RewardReducer.getShopCardStatus,
    shopCards: state.RewardReducer.shopCards,
    selectedCard: state.RewardReducer.selectedShopCards,
    updateRewardStatus: state.RewardReducer.updateRewardStatus
  };
};
export default connect(
  mapStateToProps,
  { getShopCards, collectionUpdate, setDefaultUpdate }
)(Mail);
