import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Badge,
  Table
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";

import { getEmployers } from "../../../../../../actions/reviewer_actions/employerActions";
import { addLog } from "../../../../../../actions/reviewer_actions/logActions";
import { saveEmployer } from "../../../../../../actions/userActions";


class EmployeeTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      delId: "",
      open: false,
      taskTitle: "",
      updateDialogOpen: false,
      scroll: "body",
      taskDetail: {},
      value: 0,
      title: "",
      Description: "",
      DueTime: "",
      AlottedTo: "",
      completionModal: false,
      completionNote: "",
      taskStatus: "",
      loader: true,
      PostedTime: "",
      TaskPurpose: "",
      taskCompleted: "",
      id: "",
      uid: "",
      SelectForAllot: "",
      collapse: false,
      deleteLoader: false,
      completeLoader: false,
      employers: [],
    };
  }

  handlerLink = (employer) => {
    this.props.addLog({
      reviewer: this.props.reviewer.name,
      page: "Review Employers",
      section: "All Employers",
      clicked: true,
      target: employer.name,
      timestamp: new Date()
    });
    this.props.saveEmployer(employer);
    this.props.history.push({
      pathname: "/reviewer/dashboard"
    });
  };

  componentDidMount() {
    this.props.getEmployers();
  }

  componentWillReceiveProps = nextProps => {
    console.log(nextProps);

    if(nextProps.done === "move") {
      this.setState({
        loader: false,
        employers: nextProps.employers
      })
    }
  };

  searchingForName = searchQuery => {
    return (employer) => {
      return (
        employer.name.toLowerCase().includes(searchQuery.toLowerCase()) || 
        employer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        !searchQuery
      );
    };
  };

  render() {
    const { searchQuery } = this.props;
    const { employers, loader } = this.state;

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Location</th>
                  <th>Subscription</th>
                  <th>Status</th>
                </tr>
              </thead>
            
              <tbody>
                {loader ? (
                  <td colSpan="7" style={{ paddingTop: "35px", textAlign: "center" }}>
                    <CircularProgress />
                  </td>
                ) : (
                  employers.length > 0 ? (
                    employers.filter(this.searchingForName(searchQuery)).map((emp, index) => {
                      return (
                        <tr style={{cursor: "pointer"}} onClick={() => this.handlerLink(emp)} className="taskRow" key={index} id={`toggler${index}`}>
                          <td>{index+1}</td>
                          <td>{emp.name}</td>
                          <td>{emp.email}</td>
                          <td>{emp.cell}</td>
                          <td>{emp.state}</td>
                          <td>{emp.subscription}</td>
                          <td>
                            {emp.status == 'active' ?
                            <Badge  color="success">{emp.status}</Badge>
                            : 
                            <Badge   color="secondary">{emp.status}</Badge>
                            }
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr style={{ textAlign: "center" }}>
                      <td colSpan="7">Could Not Find any Employers</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  employers: state.employerReducer.employers,
  done: state.employerReducer.done,
  loader: state.employerReducer.loader,
  reviewer: state.auth.user
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getEmployers,
      saveEmployer,
      addLog
    }
  )(EmployeeTasks)
)
