import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../../util/asyncComponent";

const ImportDB = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/importdb/wcstate`}
      />
      <Route
        path={`${match.url}/wcstate`}
        component={asyncComponent(() => import("./routes/WCState"))}
      />

      <Route
        path={`${match.url}/minwages`}
        component={asyncComponent(() => import("./routes/MinWages"))}
      />

      <Route
        path={`${match.url}/newhire`}
        component={asyncComponent(() => import("./routes/NewHire"))}
      />
      <Route
        path={`${match.url}/districts`}
        component={asyncComponent(() => import("./routes/Districts"))}
      />
      <Route
        path={`${match.url}/industry`}
        component={asyncComponent(() => import("./routes/Industry"))}
      />

      <Route
        component={asyncComponent(() =>
          import("../../../app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default ImportDB;
