import React from "react";
import StyledRadio from "components/radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import toastr from "toastr";
import { PulseLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { createReviewer } from "../../../../actions/createReviewerAction";
import { generatePassword } from "../../../../util/password";

const ContactUs = ({ match }) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    type: "reviewer",
    fName: "",
    lName: "",
    email: "",
    phone: "",
    password: "",
    loader: false,
  });

  const setType = (event) => {
    setState({
      ...state,
      type: event.target.value,
    });
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value);
    setState({
      ...state,
      [name]: value,
    });
  };

  const creatReviewer = (e) => {
    e.preventDefault();

    let { fName, lName, email, phone, type, password } = state;

    if (
      fName == "" ||
      lName == "" ||
      email == "" ||
      phone == "" ||
      password == ""
    ) {
      toastr.error("All fields are required!");
    } else if (password.length < 8) {
      toastr.error("Password should be of atleast 8 characters!");
    } else {
      setState({
        ...state,
        loader: true,
      });

      let data = {
        name: fName + " " + lName,
        email,
        phone,
        password,
        type,
      };
      console.log(data);

      dispatch(createReviewer(data, addRes));
    }
  };

  const addRes = (type) => {
    console.log("res=", type);
    if (type == "success") {
      setState({
        fName: "",
        lName: "",
        email: "",
        phone: "",
        password: "",
        loader: false,
      });
    } else {
      setState({
        ...state,
        loader: false,
      });
    }
  };

  return (
    <div className="animated slideInUpTiny animation-duration-3 cardCreate">
      <div className="row">
        <div className="col-lg-9 col-md-8 col-sm-7 col-12">
          <form className="contact-form jr-card">
            <div className="row">
              <div className="col-md-12 col-12">
                <FormControl component="fieldset">
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup
                    onChange={setType}
                    value={state.type}
                    aria-label="gender"
                    name="customized-radios"
                    className="radioGroup"
                    row
                  >
                    <FormControlLabel
                      value="reviewer"
                      control={<StyledRadio />}
                      label="reviewer"
                      className="radioCreate"
                    />
                    <FormControlLabel
                      value="authorizer"
                      control={<StyledRadio />}
                      label="authorizer"
                      className="radioCreate"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label form="firstName">Name</label>
                  <input
                    className="form-control form-control-md"
                    id="firstName"
                    name="fName"
                    onChange={onChangeHandler}
                    type="text"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    className="form-control form-control-md"
                    id="lastName"
                    type="text"
                    placeholder="Last Name"
                    name="lName"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control form-control-md"
                    id="email"
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    className="form-control form-control-md"
                    id="phoneNumber"
                    type="tel"
                    placeholder="Phone"
                    name="phone"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    className="form-control form-control-md"
                    id="password"
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group mb-0">
                  {state.loader ? (
                    <button className="btn btn-primary" disabled>
                      <PulseLoader color={"#fff"} size={12} />
                    </button>
                  ) : (
                    <button className="btn btn-primary" onClick={creatReviewer}>
                      {state.type === "reviewer"
                        ? "Add New Reviewer"
                        : "Add New Authorizer"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-lg-3 col-md-4 col-sm-5 col-12">
          <div className="contact-block jr-card py-5 px-4">
            {state.type === "reviewer" ? (
              <div className="contact-body">
                <h4 className="text-uppercase">Reviewer Can View:</h4>
                <div>
                  <ul>
                    <li>All Employers</li>
                    <li>Their Employees</li>
                    <li>Their Employees</li>
                    <li>Employer Tasks</li>
                    <li>Employee Tasks</li>
                    <li>Employee Time Sheets</li>
                    <li>Employee Paystubs</li>
                    <li>Employee Schedules</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="contact-body">
                <h4 className="text-uppercase">Authorizer Can View:</h4>
                <div>
                  <ul>
                    <li>View State Documents</li>
                    <li>Approve State Documents</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
