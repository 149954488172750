import axios from "axios";
import toastr from "toastr";
import { db } from "../firebase/firebase";
import { admin_url, giftApi } from "../Endpoint";
export const GET_SHOPCARD = "GET_SHOPCARD";
export const GET_SHOPCARD_ERR = "GET_SHOPCARD_ERR";
export const GET_SLC_SHOPCARD = "GET_SLC_SHOPCARD";
export const GET_SLC_SHOPCARD_ERR = "GET_SLC_SHOPCARD_ERR";
export const UPDATE_SHOPCARD = "UPDATE_SHOPCARD";
export const UPDATE_SHOPCARD_ERR = "UPDATE_SHOPCARD_ERR";

//get Shop Cards
export function getShopCards() {
  // "https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/getShopCards"
  return (dispatch) => {
    axios
      .get(`${admin_url}${giftApi.get_shopCard}`)
      .then((res) => {
        if (res.data === "error while getting") {
          toastr.error("Error occured. Please try again later");
          dispatch({
            type: GET_SHOPCARD_ERR,
          });
        } else {
          let cards = [];
          res.data.map((c) => {
            if (c.opening_balance === 5) {
              cards.push(c);
            } else if (c.opening_balance === 10) {
              cards.push(c);
            } else if (c.opening_balance === 25) {
              cards.push(c);
            } else if (c.opening_balance === 50) {
              cards.push(c);
            }
          });
          dispatch({
            type: GET_SHOPCARD,
            payload: cards,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toastr.error("Error occured. Please try again later");
        dispatch({
          type: GET_SHOPCARD_ERR,
        });
      });
  };
}

// get Selected shop card
export function getSelectedShopCard() {
  return (dispatch) => {
    db.collection("shopCards").onSnapshot(
      function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const data = doc.data();
          const docId = doc.id;
          datatoStore.push({ ...data, docId });
          return datatoStore;
        });

        console.log("selected card", datatoStore);

        dispatch({
          type: GET_SLC_SHOPCARD,
          payload: datatoStore,
        });
      },
      function (err) {
        dispatch({
          type: GET_SLC_SHOPCARD_ERR,
        });
      }
    );
  };
}

// update collection

export const collectionUpdate = (newArray, oldArray) => {
  console.log("newArray", newArray);
  console.log("oldArray", oldArray);

  return (dispatch) => {
    var batch = db.batch();
    // delete old documents
    oldArray.forEach((item) => {
      if (item.docId) {
        let docRef = db.collection("shopCards").doc(item.docId);
        batch.delete(docRef);
      }
    });
    // addNewDocs
    newArray.forEach((item) => {
      var docRef = db.collection("shopCards").doc();
      batch.set(docRef, item);
    });
    batch
      .commit()
      .then(() => {
        toastr.success("successfully updated");

        dispatch({
          type: UPDATE_SHOPCARD,
        });
      })
      .catch((err) => {
        console.log("err", err);
        toastr.error("Error occured. Please try again later");

        dispatch({
          type: UPDATE_SHOPCARD_ERR,
        });
      });
  };
};

export const setDefaultUpdate = () => {
  return {
    type: "SET_DEFAULT_UPDATE",
  };
};
