import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./routes/header";
import Footer from "components/Footer";

import ColorOption from "../../reviewer/containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../../util/asyncComponent";
import TopNav from "components/TopNav";
import Documents from "./routes/allDocuments";
import StateSchedules from './routes/stateSchedules';
import NewEmployees from './routes/newEmployees'

class App extends React.Component {



  render() {
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container`}>
        <div className="app-main-container">
          <div className={`app-header app-header-horizontal`}>
            <Header />

            <TopNav />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  exact
                  path={`/authorizer/schedule`}
                  component={StateSchedules}
                />
                <Route
                  exact
                  path={`/authorizer/new-employees`}
                  component={NewEmployees}
                />
                <Route
                  exact
                  path={`/authorizer/all-documents`}
                  component={Documents}
                />

                <Route
                  component={asyncComponent(() =>
                    import("../../reviewer/app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
