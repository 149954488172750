import React from "react";
import MailListItem from "./MailListItem";
import CustomScrollbars from "util/CustomScrollbars";

const MailList = ({
  mails,
  onMailSelect,
  onMailChecked,
  onStartSelect,
  width
}) => {
  return (
    <div className="module-list mail-list">
      <CustomScrollbars
        className="module-list-scroll scrollbar"
        style={{
          height: "100vh"
        }}
      >
        {mails.map((mail, index) => (
          <MailListItem
            key={index}
            mail={mail}
            onMailSelect={onMailSelect}
            onMailChecked={onMailChecked}
            onStartSelect={onStartSelect}
          />
        ))}
      </CustomScrollbars>
    </div>
  );
};

export default MailList;
