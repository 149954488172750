import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Sidebar from "../containers/SideNav/index";
import Footer from "../../components/Footer";

//own
import Customers from "./routes/customers";
import Documents from "./routes/documents";
import ImportDB from "./routes/importdb";
import CreateReviewer from "./routes/createReviewer";
import DiscountCoupons from "./routes/discountCoupons";
import TermsPolicy from "./routes/terms";
import ShopCardGift from "./routes/shopCards/basic";
import { getSelectedShopCard } from "actions/rewardAction";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../../constants/ActionTypes";

import ColorOption from "../containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../../util/asyncComponent";
import TopNav from "../../components/TopNav";
import TicketSystem from "./routes/ticketSystem";

class App extends React.Component {
  componentDidMount() {
    this.props.getSelectedShopCard();
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } =
      this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                {/* own code */}
                <Route path={`${match.url}/dashboard`} component={Customers} />

                <Route
                  path={`${match.url}/createreviewer`}
                  component={CreateReviewer}
                />

                <Route path={`${match.url}/documents`} component={Documents} />
                <Route
                  path={`${match.url}/shopcards`}
                  component={ShopCardGift}
                />
                <Route path={`${match.url}/importdb`} component={ImportDB} />

                <Route
                  path={`${match.url}/discountcoupons`}
                  component={DiscountCoupons}
                />

                <Route path={`${match.url}/terms`} component={TermsPolicy} />
                <Route path={`${match.url}/tickets`} component={TicketSystem} />
                {/* end of own code */}

                <Route
                  component={asyncComponent(() =>
                    import("../app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(
  connect(mapStateToProps, { getSelectedShopCard })(App)
);
