import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../../util/asyncComponent";

const Customers = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/employees`} />
      <Route
        path={`${match.url}/employees`}
        component={asyncComponent(() => import("./routes/review_tasks/EmployeeTask"))}
      />
      <Route
        path={`${match.url}/completed`}
        component={asyncComponent(() => import("./routes/review_tasks/CompletedTask"))}
      />
      <Route
        path={`${match.url}/employer`}
        component={asyncComponent(() => import("./routes/review_tasks/EmployerTask"))}
      />
      <Route
        path={`${match.url}/timesheets`}
        component={asyncComponent(() => import("./routes/review_payroll/TimeSheets/SelectEmployee"))}
      />
      <Route
        path={`${match.url}/timesheetdetails`}
        component={asyncComponent(() => import("./routes/review_payroll/TimeSheets"))}
      />
      <Route
        path={`${match.url}/paystubs`}
        component={asyncComponent(() => import("./routes/review_payroll/PayStubs/SelectEmployee"))}
      />
      <Route
        path={`${match.url}/paystubdetails`}
        component={asyncComponent(() => import("./routes/review_payroll/PayStubs/SelectPayStubs"))}
      />
      <Route
        path={`${match.url}/viewemployees`}
        component={asyncComponent(() => import("./routes/review_employees/ViewEmployees"))}
      />
      <Route
        path={`${match.url}/schedule`}
        component={asyncComponent(() => import("./routes/review_schedule/ViewSchedule"))}
      />
      <Route
        path={`${match.url}/employeeschedule`}
        component={asyncComponent(() => import("./routes/review_schedule/ViewSchedule/components/ViewSchadule"))}
      />
      <Route
        path={`${match.url}/scheduledetails`}
        component={asyncComponent(() => import("./routes/review_schedule/ViewSchedule/components/ScheduleDetails"))}
      />
      <Route
        path={`${match.url}/inbox`}
        component={asyncComponent(() => import("./routes/review_schedule/Contact/index"))}
      />
      <Route
        path={`${match.url}/setup`}
        component={asyncComponent(() => import("./routes/setup/basic"))}
      />

      <Route
        component={asyncComponent(() =>
          import("../../../app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default Customers;
