import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import darkTheme from "./themes/darkTheme";
import AppLocale from "../../lngProvider";
import { IntlProvider } from "react-intl";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";

import MainApp from "../app";
import AllEmployers from "../app/routes/customers/routes/AllEmployers";
import SignIn from "./SignIn";
import ForgetPassword from "./ForgetPassword";
import { setInitUrl } from "../../actions/Auth";
import asyncComponent from "util/asyncComponent";
import Authorizer from "../../authorizer/app";

const RestrictedRouteReviewer = ({
  component: Component,
  userStatus,
  userType,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      userStatus && (userType === "reviewer") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/reviewersignin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const RestrictedRouteAuthorizer = ({
  component: Component,
  userStatus,
  userType,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      userStatus && ( userType === "authorizer") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/reviewersignin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  }

  render() {
    const {
      match,
      location,
      themeColor,
      isDarkTheme,
      locale,
      userStatus,
      initURL,
      isDirectionRTL,
      userType
    } = this.props;
    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
      document.body.classList.add("dark-theme");
      applyTheme = createMuiTheme(darkTheme);
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }
    if (location.pathname === "/") {
      if (userStatus === false) {
        return <Redirect to={"/reviewersignin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/reviewersignin") {
        if (userType === "reviewer") {
          return <Redirect to={"/reviewer/dashboard/employers"} />;
        } else {
         return <Redirect to={"/authorizer/schedule"} />;
        }
      } else {
        return <Redirect to={initURL} />;
      }
    }
    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <div className="app-main">
            <Switch>
              <RestrictedRouteReviewer
                path={`${match.url}reviewer/dashboard/employers`}
                userStatus={userStatus}
                component={AllEmployers}
                userType={userType}
                // exact
              />
               <RestrictedRouteReviewer
                    path={`${match.url}reviewer`}
                    userStatus={userStatus}
                    component={MainApp}
                    userType={userType}
                  />

              <RestrictedRouteAuthorizer
                path={`${match.url}authorizer`}
                userStatus={userStatus}
                component={Authorizer}
                userType={userType}
              />

              <Route path="/reviewersignin" component={SignIn} />
              <Route path="/forgetpassword" component={ForgetPassword} />
              <Route
                component={asyncComponent(() =>
                  import("../app/routes/extraPages/routes/404")
                )}
              />
            </Switch>
          </div>
        </IntlProvider>
        {/* </MuiPickersUtilsProvider> */}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL
  } = settings;
  const { userStatus, initURL, userType } = auth;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL,
    userStatus,
    initURL,
    userType
  };
};

export default connect(mapStateToProps, { setInitUrl })(App);
