import { db } from "../firebase/firebase";
import toastr from "toastr";

export const GETEMPLOYER = "GETEMPLOYER";
export const SAVEEMPLOYER = "SAVEEMPLOYER";
export const ALL_TICKETS_GET_START = "ALL_TICKETS_GET_START";
export const ALL_TICKETS_GET_SUCCESSFULLY = "ALL_TICKETS_GET_SUCCESSFULLY";
export const ALL_TICKETS_GET_FAILED = "ALL_TICKETS_GET_FAILED";
export const GETUSER = "GETUSER";

//Save Employer
export function saveEmployer(data) {
  return async (dispatch) => {
    await localStorage.setItem("employer", JSON.stringify(data));
    dispatch({
      type: SAVEEMPLOYER,
      data: data,
    });
  };
}

//Get Employer
export function getEmployer() {
  let employer = JSON.parse(localStorage.getItem("employer"));
  console.log("Employer In Actions: ", employer);
  return (dispatch) => {
    dispatch({
      type: GETEMPLOYER,
      data: employer,
    });
  };
}

export const getAllTickets = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_TICKETS_GET_START });
    db.collection("supportTickets").onSnapshot(function (querySnapshot) {
      var allTickets = [];
      querySnapshot.forEach(function (doc) {
        allTickets.push({ docId: doc.id, ...doc.data() });
      });
      dispatch({ type: ALL_TICKETS_GET_SUCCESSFULLY, payload: allTickets });
    });
  } catch (err) {
    dispatch({ type: ALL_TICKETS_GET_FAILED });
  }
};

export const changeTicketStatus = (data) => async (dispatch) => {
  try {
    // console.log("status is here", data.status);
    db.collection("supportTickets")
      .doc(data.docId)
      .update({
        status: data.status,
      })
      .then(() => {
        toastr.success("updated successfully!");
      });
  } catch (err) {
    toastr.error("An error occurred!");
  }
};

//current user get;
// export function startGetCurrentUser() {
//   return dispatch => {
//     //   var user = auth.currentUser;

//     auth.onAuthStateChanged(user => {
//       console.log("current user", user);
//       if (user) {
//         db.collection("users")
//           .where("email", "==", user.email)
//           .get()
//           .then(function(querySnapshot) {
//             let datatoStore = {};
//             querySnapshot.forEach(function(doc) {
//               let data = doc.data();
//               let docid = doc.id;
//               let final = {
//                 ...data,
//                 docid
//               };
//               datatoStore = final;
//             });

//             if (datatoStore.stripeCustomer) {
//               if (datatoStore.status == "active") {
//                 let datas = {
//                   ...datatoStore,
//                   uid: user.uid
//                 };
//                 console.log("data from net", datas);
//                 dispatch({
//                   type: GETUSER,
//                   payload: datas
//                 });
//               } else {
//                 dispatch({
//                   type: GETUSERERR,
//                   payload: "nill"
//                 });
//                 toast.error(
//                   "Your account is blocked by Admin, Contact to admin for more information"
//                 );
//               }
//             } else {
//               dispatch({
//                 type: GETUSERERR,
//                 payload: "nill"
//               });
//             }
//           });
//       } else {
//         dispatch({
//           type: GETUSERERR,
//           payload: "nill"
//         });
//       }
//     });
//   };
// }
