import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Row, Col, Label, Input } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { startRessetPassword } from "../../../../actions/reviewer_actions/employeeUserActions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import HomeIcon from '@material-ui/icons/Home';
import "../styles/style.css";
class LogInForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      disableBtn: false,
      email: "",
      password: "",
      invalidEmail: "",
      invPswrd: "",
      loader: false,
      hasErroredErr: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      loader: false,
      disableBtn: false,
    });

    if (nextProps.forgetWait === "move") {
      this.props.history.push("/reviewersignin");
    }
  };

  onChangeHandler = (e) => {
    this.setState({ email: e.target.value });
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-z  A-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  login = () => {
    if (this.state.email == "" || !this.validateEmail(this.state.email)) {
      this.setState({
        invalidEmail: "invalid email address",
      });
    } else {
      this.setState({
        invalidEmail: "",
        loader: true,
      });
      this.props.startRessetPassword(this.state.email);
    }
  };

  render() {
    const { disableBtn, email } = this.state;
    return (
      // <form className="form">
      //   <div className="form__form-group">
      //     <span className="form__form-group-label">Email</span>
      //     <div className="form__form-group-field">
      //       <div className="form__form-group-icon">
      //         {/* <AccountOutlineIcon /> */}
      //       </div>

      //       <Input
      //         type="email"
      //         name="email"
      //         id="exampleEmail"
      //         placeholder="Email"
      //         defaultValue={email}
      //         className="textfield"
      //         onChange={(event) => this.onChangeHandler(event)}
      //       />

      //       <Tooltip
      //         TransitionComponent={Zoom}
      //         title="Enter your email address e.g abc@example.com"
      //       >
      //         <IconButton className="helpButton">
      //           {/* <img className="helpImage" src={HelpIcon} alt="help"/> */}
      //         </IconButton>
      //       </Tooltip>
      //     </div>
      //     <div className="account__forgot-password">
      //       <Link to="/reviewersignin">Login Page</Link>
      //     </div>
      //     <span style={{ color: "red" }}> {this.state.invalidEmail}</span>
      //   </div>

      //   <div className="form__form-group">
      //     <div className="form__form-group-field">
      //       <p>
      //         Add your email address, we will send you a email where you can
      //         change your password
      //       </p>
      //     </div>

      //     {this.state.loader ? (
      //       <Row>
      //         <Col style={{ textAlign: "center" }}>
      //           <CircularProgress size="22px" />
      //         </Col>
      //       </Row>
      //     ) : (
      //       <Row style={{ marginTop: "15px" }}>
      //         <Col>
      //           <Button
      //             style={{ width: "100%" }}
      //             color="success"
      //             className="square"
      //             outline
      //             onClick={this.login}
      //           >
      //             Forget Password
      //           </Button>
      //         </Col>
      //       </Row>
      //     )}
      //   </div>
      // </form>
      <div className="wrapper">
        <div style={{ position: 'absolute', left: 40, top: 30 }}>
          <IconButton
            onClick={() => this.props.history.push("/reviewersignin")}
            style={{
              backgroundColor: 'white',
            }}
          >
            <HomeIcon
              style={{
                color: '#397e3d',
              }}
            />
          </IconButton>
        </div>
        <div className="form_container">
          <p className="title">Forget Password Panel</p>
          <p className="title_description">
            Please login using your account
          </p>

          <div>
            <span className="form_group_label_account" style={{ color: "black" }}>EMAIL</span>
            <input
              className={`custom_input mt-1 my-sm-3`}
              label={'Email'}
              fullWidth
              onChange={(event) => this.onChangeHandler(event)}
              defaultValue={email}
              margin="normal"
            />
          </div>
          <span style={{ color: "red" }}> {this.state.invalidEmail}</span>
          <button
            onClick={this.login}
            style={{
              marginTop: 10,
              color: 'white',
              backgroundColor: '#397e3d',
              borderRadius: '14px',
              fontWeight: 700,
              fontSize: '16px',
              width: '100%',
              border: "1px solid #397e3d",
              height: '60px',
              boxShadow: '#397e3d 0px 30px 30px -20px',
              marginBottom: '10px',
            }}
          >
            Forget Password
          </button>
          <div onClick={() => { this.props.history.push("/reviewersignin"); }} style={{ cursor: "pointer", marginTop: 20 }}>
            Login Page
          </div>
        </div>
        <div>
          <div
            style={{
              marginLeft: '0px',
              width: '100%',
              position: 'absolute',
              zIndex: 1,
              left: 0,
              bottom: 0,
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#f2f4f7',
              color: 'black',
            }}
          >
            © {new Date().getFullYear()} helpara.com - All Rights Reserved
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.employeeUserReducer.forget,
    forgetWait: state.employeeUserReducer.forgetWait,
  };
};

export default connect(mapStateToProps, { startRessetPassword })(
  withRouter(LogInForm)
);
