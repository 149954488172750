import React, { Component } from "react";
import { withStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { compose } from "recompose";
import { BeatLoader } from "react-spinners";
import { getTemplateSchema } from "actions/NewSystemDocuments";
import { generatePDF } from "actions/authorizerAction/allDocs";

import { BounceLoader } from "react-spinners";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SignaturePad from "react-signature-canvas";

function getSteps() {
  return ["Fill the Form"];
}

class PdfFillForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schemaloader: true,

      data: {
        employee: {},
        employer: {}
      },
      activeStep: 0,
      title: "",
      category: "none",
      condition: "none",
      price: "",
      discriptions: "",
      tag: "",
      loading: false,

      generatePdfLoader: false,
      uploadLoading: false,
      testFileName: "",
      orginalfilename: "",
      fileNameOnServer: "test.pdf"
    };
  }

  clear = () => {
    this.sigPad.clear();
  };

  trim = item => {
    let data = {
      base64: this.sigPad
        .getTrimmedCanvas()
        .toDataURL("image/png")
        .slice(22)
    };

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        employee: {
          ...prevState.data.employee,
          [item]: data
        }
      }
    }));
  };

  sigPad = {};

  onChangeCheckboxHandler = name => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        employee: {
          ...prevState.data.employee,
          [name]: !prevState.data.employee[name]
        }
      }
    }));
  };

  onChangeDataHandler = e => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        employee: {
          ...prevState.data.employee,
          [name]: value
        }
      }
    }));
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={8}>
            <Grid item xs={12} md={12} className="paddingTop rowKeys">
              <Typography variant="caption" align="center">
                Please Fill Test Form, and test the pdf
              </Typography>
            </Grid>
            {Object.keys(this.props.templateSchema.properties).map(
              (key, index) => {
                if (key.substring(0, 2) !== "ep") {
                  if (
                    this.props.templateSchema.properties[key].type == "string"
                  )
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        className="rowKeys"
                      >
                        <TextField
                          fullWidth={true}
                          onChange={this.onChangeDataHandler}
                          required={
                            this.props.templateSchema.required &&
                            this.props.templateSchema.required.includes(key)
                          }
                          label={this.state[key]}
                          name={this.state[key]}
                          value={this.state.data.employee[this.state[key]]}
                        />
                      </Grid>
                    );
                  else if (
                    this.props.templateSchema.properties[key].type == "boolean"
                  )
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={12}
                        className="rowKeys"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.data.employee[this.state[key]]
                              }
                              onChange={() =>
                                this.onChangeCheckboxHandler(this.state[key])
                              }
                              value={this.state.data.employee[this.state[key]]}
                              color="primary"
                            />
                          }
                          label={this.state[key]}
                        />
                      </Grid>
                    );
                  else return <div />;
                }
              }
            )}

            {Object.keys(this.props.templateSchema.properties).map(
              (key, index) => {
                if (key.substring(0, 2) !== "ep") {
                  return this.props.templateSchema.properties[key].type ==
                    "object" ? (
                    <Grid key={index} item xs={12} md={12} className="rowKeys">
                      <div
                        style={{
                          backgroundColor: "lightgray",
                          width: 200,
                          height: 200
                        }}
                      >
                        <p>
                          {" "}
                          {this.props.templateSchema.properties[key].title}{" "}
                        </p>
                        <SignaturePad
                          canvasProps={{ width: 200, height: 200 }}
                          ref={ref => {
                            this.sigPad = ref;
                          }}
                        />
                      </div>

                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={this.clear}
                      >
                        clear
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => this.trim(this.state[key])}
                      >
                        save
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  );
                }
              }
            )}
          </Grid>
        );

      default:
        return "Unknown step";
    }
  };

  componentDidMount() {
    this.props.getTemplateSchema(this.props.template_id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.templateSchema) {
      Object.keys(nextProps.templateSchema.properties).map((key, index) => {
        if (key.substring(0, 2) !== "ep") {
          this.setState({
            [key]: key
          });
        } else {
          this.setState({
            [key]: key.slice(2)
          });
        }
      });
    }

    this.setState({ schemaloader: false, loading: false });
  }

  handleNext = () => {
    if (this.state.activeStep === 1) {
      Object.keys(this.props.templateSchema.properties).map((key, index) => {
        if (key.substring(0, 2) !== "ep") {
          if (this.props.templateSchema.properties[key].type == "boolean") {
            if (this.props.employee.hasOwnProperty(this.state[key])) {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employee: {
                    ...prevState.data.employee,
                    [this.state[key]]: this.props.employee[this.state[key]]
                  }
                }
              }));
            } else {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employee: {
                    ...prevState.data.employee,
                    [this.state[key]]: false
                  }
                }
              }));
            }
          } else {
            if (this.props.employee.hasOwnProperty(this.state[key])) {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employee: {
                    ...prevState.data.employee,
                    [this.state[key]]: this.props.employee[this.state[key]]
                  }
                }
              }));
            } else {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employee: {
                    ...prevState.data.employee,
                    [this.state[key]]: ""
                  }
                }
              }));
            }
          }
        }
      });
    } else if (this.state.activeStep === 2) {
      Object.keys(this.props.templateSchema.properties).map((key, index) => {
        if (key.substring(0, 2) === "ep") {
          if (this.props.templateSchema.properties[key].type == "boolean") {
            if (this.props.employer.hasOwnProperty(this.state[key])) {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employer: {
                    ...prevState.data.employer,
                    [this.state[key]]: this.props.employer[this.state[key]]
                  }
                }
              }));
            } else {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employer: {
                    ...prevState.data.employer,
                    [this.state[key]]: false
                  }
                }
              }));
            }
          } else {
            if (this.props.employer.hasOwnProperty(this.state[key])) {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employer: {
                    ...prevState.data.employer,
                    [this.state[key]]: this.props.employer[this.state[key]]
                  }
                }
              }));
            } else {
              this.setState(prevState => ({
                data: {
                  ...prevState.data,
                  employer: {
                    ...prevState.data.employer,
                    [this.state[key]]: ""
                  }
                }
              }));
            }
          }
        }
      });
    }
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleNextFinesh = () => {
    this.setState({ generatePdfLoader: true });

    let data = {};
    Object.keys(this.props.templateSchema.properties).map((key, index) => {
      if (key.substring(0, 2) !== "ep") {
        data[key] = this.state.data.employee[this.state[key]];
      } else {
        data[key] = this.state.data.employer[this.state[key]];
      }
    });

    let sendToServer = {
      data: data,
      id: this.props.template_id
    };
    this.props.generatePDF(sendToServer, this.handleRes);
  };

  handleRes = (type, data) => {
    this.setState({ generatePdfLoader: false });
    if (type === "success") {
      this.setState({
        testFileName: data.submission.download_url,
        activeStep: this.state.activeStep + 1
      });
    } else {
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  render() {
    var isValid;
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    isValid = false;
    this.props.templateSchema.required &&
      this.props.templateSchema.required.map((key, index) => {
        if (key.substring(0, 2) !== "ep") {
          if (
            this.state.data.employee[this.state[key]] == undefined ||
            this.state.data.employee[this.state[key]] == ""
          ) {
            isValid = true;
          }
        }
      });

    return this.state.schemaloader ? (
      <Grid item align="center" className="loaderForm">
        <BounceLoader color={"#123abc"} />
      </Grid>
    ) : (
      <div className={classes.main}>
        <div className={classes.root}>
          {Object.keys(this.props.templateSchema.properties).length > 0 ? (
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {this.getStepContent(index)}
                      <div className={classes.actionsContainer}>
                        <div className="paddingTop">
                          {activeStep === steps.length - 1 ? (
                            this.state.generatePdfLoader ? (
                              <Button
                                disabled={isValid}
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.button}
                              >
                                <BeatLoader size="16" color={"#123abc"} />
                              </Button>
                            ) : (
                              <Button
                                disabled={isValid}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={this.handleNextFinesh}
                                className={classes.button}
                              >
                                Generate Pdf
                              </Button>
                            )
                          ) : (
                            <Button
                              disabled={isValid}
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={this.handleNext}
                              className={classes.button}
                            >
                              Next
                            </Button>
                          )}
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
          ) : (
            <div> </div>
          )}
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography align="center">
                All steps completed - you&quot;re finished!
                <span style={{ marginLeft: 30 }}>
                  <a href={`${this.state.testFileName}`} target="_blank">
                    <b>Show Pdf</b>
                  </a>
                </span>
              </Typography>
              <Grid container spacing={8}>
                <Grid item xs={12} md={12} className="paddingTop rowKeys">
                  <Typography align="center">
                    If you found an error, make a change request to admin with
                    error details.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templateSchema: state.SystemDocuments.templateSchema,
  employee: state.SystemDocuments.employee,
  employer: state.SystemDocuments.employer,
  schema_loader: state.SystemDocuments.schema_loader,
  add_verified_doc_loader: state.SystemDocuments.add_verified_doc_loader
});

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
    margin: "auto"
  },
  sigPad: {
    width: "100%",
    height: "100%"
  },
  main: {
    width: "100%"
  }
});
PdfFillForm.propTypes = {
  classes: PropTypes.object
};

export default compose(
  connect(mapStateToProps, {
    getTemplateSchema,
    generatePDF
  }),
  withStyles(styles)
)(PdfFillForm);
