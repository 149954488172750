import React from "react";
import { connect } from "react-redux";
import { startGetCurrentUser } from "actions/Auth";

import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { history } from "./store";

import AppComponent from "./reviewer/containers/App";
import AdminComponent from "./admin/containers/App";
class CheckingUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loader: true,
      userType: "",
    };
  }
  componentWillMount() {
    this.props.startGetCurrentUser();
  }

  componentWillReceiveProps(nextProps) {
    // console.log("=========nextProps.user, userType===========================");
    // console.log(nextProps.user, nextProps.userType);
    // console.log("====================================", nextProps.userStatus);
    this.setState({ loader: false });
    if (nextProps.userStatus) {
      this.setState({
        userType: nextProps.user.type,
      });
    }
    // else {
    //   if(window.location.pathname === "/adminsignin" || window.location.pathname === "/"){

    //     this.setState({
    //       userType: "admin"
    //     });
    //   }else{
    //     this.setState({
    //       userType: "reviewer"
    //     });
    //   }
    //   // this.setState({
    //   //   userType: nextProps.userType
    //   // });
    // }
  }

  render() {
    const { userType } = this.state;
    const location = window.location.pathname;
    console.log("=========userType===========================");
    console.log(userType);
    console.log("====================================");
    return (
      <div style={{ width: "100%" }}>
        {this.state.loader ? (
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle
                className="path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke-width="2"
                stroke-miterlimit="10"
              />
            </svg>
          </div>
        ) : userType === "reviewer" ||
          userType === "authorizer" ||
          location === "/reviewersignin" ? (
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={AppComponent} />
            </Switch>
          </ConnectedRouter>
        ) : (
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={AdminComponent} />
            </Switch>
          </ConnectedRouter>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userStatus: state.auth.userStatus,
    getuserErr: state.auth.getuserErr,
    user: state.auth.user,
    loader: state.auth.loader,
    userType: state.auth.userType,
  };
};
export default connect(mapStateToProps, { startGetCurrentUser })(CheckingUser);
