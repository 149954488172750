import { db } from "../../firebase/firebase";
import toastr from "toastr";

export const GET_NEW_EMPLOYEE = "GET_NEW_EMPLOYEE";


export const getNewEmployee = cb => {
  return dispatch => {
    db.collection("users")
      .where("stateDocStatus", "==", "pending")
      .onSnapshot(
        querySnapshot => {
          let dataToStore = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const id = doc.id;
            dataToStore.push({ id, ...data });
          });
          dispatch({
            type: GET_NEW_EMPLOYEE,
            payload: dataToStore
          });
          cb("success");
        },
        err => {
          console.log("err getting employee", err);
          toastr.error("Error Occurred. Try again later");
          cb("error", err);
        }
      );
  };
};
