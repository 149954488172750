import {
  GET_ALL_CUSTOMERS,
  GET_ALL_CUSTOMERS_ERR,
  CHANGE_STATUS,
  CHANGE_STATUS_ERR,
  GET_ALL_LOGS,
  CHANGE_SUBSCRIPTION_STATUS,
  CHANGE_SUBSCRIPTION_STATUS_ERR,
} from "constants/ActionTypes";

var initialState = {
  loader: new Date(),
  customers: [],
  logs: [],
  getCustomerStatus: "not done",
  getLogsStatus: "not done",
  changeStatus: "not done",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CUSTOMERS:
      return {
        ...state,
        loader: new Date(),
        customers: action.payload,
        getCustomerStatus: "done",
      };
    case GET_ALL_LOGS:
      console.log("In Reducer Logs", action.payload);
      return {
        ...state,
        loader: new Date(),
        logs: action.payload,
        getLogsStatus: "done",
      };
    case GET_ALL_CUSTOMERS_ERR:
      return {
        ...state,
        loader: new Date(),
        getCustomerStatus: "error",
      };
    case CHANGE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        loader: new Date(),
        changeStatus: "done",
        value: action.payload,
      };
    case CHANGE_SUBSCRIPTION_STATUS_ERR:
      return {
        ...state,
        loader: new Date(),
        changeStatus: "error",
      };
    case GET_ALL_CUSTOMERS_ERR:
      return {
        ...state,
        loader: new Date(),
        changeStatus: "error",
      };

    default:
      return state;
  }
}
