import { db } from "../firebase/firebase";
import toastr from "toastr";
import {
    GET_ALL_COUPONS,
    GET_ALL_COUPONS_ERR,
    UPDATE_COUPON,
    UPDATE_COUPON_ERR,
    CREATE_COUPON,
    CREATE_COUPON_ERR
} from "constants/ActionTypes";

export const getAllCoupons = () => {
  return dispatch => {
    db.collection("coupons").onSnapshot(
      function(querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(doc => {
            const data = doc.data();
            const id = doc.id;
            datatoStore.push({ id, ...data });
        });
        console.log("============datatoStore========================");
        console.log(datatoStore);
        console.log("====================================");

        dispatch({
          type: GET_ALL_COUPONS,
          payload: datatoStore
        });
      },
      error => {
        dispatch({
          type: GET_ALL_COUPONS_ERR
        });
      }
    );
  };
};

export const updateCoupon = (data) => {
  return dispatch => {
    db.collection("coupons").doc(data.id)
      .set({
          name: data.name,
          percentDiscount: data.percentDiscount,
          validFrom: data.validFrom,
          validUpto: data.validUpto
        },{ merge: true
      })
      .then(res => {
        toastr.success("Coupon updated successfully");
        dispatch({
          type: UPDATE_COUPON,
          payload: data
        });
      })
      .catch(error => {
        toastr.error("Error occurred. Please try again later");
        dispatch({
          type: UPDATE_COUPON_ERR
        });
      });
  };
};

export const createCoupon = (data) => {
  return dispatch => {
    db.collection("coupons")
      .add({
        name: data.name,
        percentDiscount: data.percentDiscount,
        code: data.code,
        validFrom: data.validFrom,
        validUpto: data.validUpto
      })
      .then(res => {
        toastr.success("Coupon created successfully");
        dispatch({
          type: CREATE_COUPON,
          payload: data
        });
      })
      .catch(error => {
        toastr.error("Error occurred. Please try again later");
        dispatch({
          type: CREATE_COUPON_ERR
        });
      });
  };
};