
import { CANCEL_SUBSCRIPTION ,CHANGE_SUBSCRIPTION} from "../../actions/SubscriptionActions";

var initialState = {
  loader:'null',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION: {
      return {
        ...state,
        loader: new Date(),
      };
    }

    case CHANGE_SUBSCRIPTION: {
      return {
        ...state,
        loader: new Date(),
      };
    }
   
    default:
      return state;
  }
}
