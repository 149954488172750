import React, { Component } from "react";
import PropTypes from "prop-types";
import CardBox from "components/CardBox";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DateTimePicker from "./DateTimePicker";
import { compose } from "recompose";
import { connect } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import { updateCoupon } from "actions/couponActions";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: 5,
  },
  Number: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontWeight: "bold",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 25,
  },
});

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.doc.id,
      name: props.doc.name,
      percentDiscount: props.doc.percentDiscount,
      code: props.doc.code,
      validFrom: props.doc.validFrom.toDate(),
      validUpto: props.doc.validUpto.toDate(),
      isEditable: false,
      updateLoader: false,
    };
    // console.log('PROPS*************', this.props.index);
  }

  handleFromDateChange = (date) => {
    this.setState({ validFrom: date });
  };

  handleUptoDateChange = (date) => {
    this.setState({ validUpto: date });
  };

  updateCoupon = () => {
    if (this.state.name == "") {
      toastr.error("Please enter coupon name");
    } else if (this.state.percentDiscount == "") {
      toastr.error("Please enter discount percentage");
    } else if (this.state.percentDiscount > 100) {
      toastr.error("Discount cannot be greater than 100%");
    } else if (this.state.validUpto < this.state.validFrom) {
      toastr.error("Invalid coupon expiry date");
    } else {
      this.setState({
        updateLoader: true,
      });
      this.props.updateCoupon({
        id: this.state.id,
        name: this.state.name,
        percentDiscount: this.state.percentDiscount,
        code: this.state.code,
        validFrom: new Date(this.state.validFrom),
        validUpto: new Date(this.state.validUpto),
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      isEditable: false,
      updateLoader: false,
    });
  }

  render() {
    return (
      <CardBox key={this.props.index} styleName="col-lg-12">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 30,
              marginLeft: 12,
            }}
          >
            <img
              style={{
                alignSelf: "flex-end",
                marginTop: -20,
                marginRight: -20,
              }}
              src={require("../../../../../assets/coupon.png")}
            />
            <div>
              <h3>
                <b>Coupon Name: </b>
                {this.state.isEditable ? (
                  <TextField
                    style={{ marginTop: -7, marginLeft: 5 }}
                    name="name"
                    type="text"
                    value={this.state.name}
                    onChange={(event) =>
                      this.setState({ name: event.target.value })
                    }
                  />
                ) : (
                  <>
                    {this.state.name}
                    <i
                      className="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"
                      style={{color:"blue"}}
                      onClick={() => this.setState({ isEditable: true })}
                    />
                  </>
                )}
              </h3>
            </div>
            <div>
              <h3>
                <b>Percent Discount: </b>
                {this.state.isEditable ? (
                  <TextField
                    style={{ marginTop: -7, marginLeft: 5 }}
                    name="discount"
                    type="number"
                    value={this.state.percentDiscount}
                    onChange={(event) =>
                      this.setState({ percentDiscount: event.target.value })
                    }
                  />
                ) : (
                  <>
                    {this.state.percentDiscount}
                    <b>%</b>
                  </>
                )}
              </h3>
            </div>
            <div>
              <h3>
                <b>Coupon Code: </b>
                {this.state.code}
              </h3>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <CardBox key={this.props.i} styleName="col-lg-6">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <h3>
                    <i
                      color="blue"
                      className="zmdi zmdi-calendar-check zmdi-hc-fw zmdi-hc-lg"
                    />
                    Valid From
                  </h3>
                </div>
                <div>
                  {this.state.isEditable ? (
                    <DateTimePicker
                      handleDateChange={this.handleFromDateChange}
                      date={this.state.validFrom}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {moment(this.state.validFrom).format(
                            "MMMM Do h:mm a"
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: "#989898",
                          width: "100%",
                          marginTop: 3,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </CardBox>
            <CardBox key={this.props.i} styleName="col-lg-6">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <h3>
                    <i
                      color="blue"
                      className="zmdi zmdi-calendar-close zmdi-hc-fw zmdi-hc-lg"
                    />
                    Valid Upto
                  </h3>
                </div>
                <div>
                  {this.state.isEditable ? (
                    <DateTimePicker
                      handleDateChange={this.handleUptoDateChange}
                      date={this.state.validUpto}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span style={{ fontSize: 16 }}>
                          {moment(this.state.validUpto).format(
                            "MMMM Do h:mm a"
                          )}
                        </span>
                      </div>
                      <div
                        style={{
                          height: 1,
                          backgroundColor: "#989898",
                          width: "100%",
                          marginTop: 3,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </CardBox>
          </div>
          {this.state.isEditable && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {this.state.updateLoader ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 13 }}
                >
                  loading...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 13 }}
                  onClick={() => this.updateCoupon()}
                >
                  Update
                </Button>
              )}
            </div>
          )}
        </div>
      </CardBox>
    );
  }
}

Row.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  updateCouponStatus: state.couponsReducer.updateCouponStatus,
  loader: state.couponsReducer.loader,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    updateCoupon,
  })
)(Row);
