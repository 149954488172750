import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./adminReducers/Settings";
import customerReducer from "./adminReducers/customerReducer";
import apiReducer from "./adminReducers/apiReducer";

import Auth from "./adminReducers/Auth";
import SystemDocuments from "./adminReducers/SystemDocuments";
import Subscriptions from "./adminReducers/Subscriptions";
import userReducer from "./reviewer_reducers/userReducer";
import TaskReducer from "./reviewer_reducers/TaskReducer";
import employerReducer from "./reviewer_reducers/employerReducer";
import scheduleReducer from "./reviewer_reducers/scheduleReducer";
import shiftReducer from "./reviewer_reducers/shiftReducer";
import employeeUserReducer from "./reviewer_reducers/employeeUserReducer";
import payStubsReducer from "./reviewer_reducers/payStubsReducer";
import createReviewerReducer from "./adminReducers/createReviewerReducer";
import dbReducers from "./adminReducers/dbReducers";
import RewardReducer from "./adminReducers/rewardReducer";
import termsPrivacyReducer from "./adminReducers/termsPrivacyReducer";
import couponsReducer from "./adminReducers/couponsReducer";
import newEmployee from './authorizerReducers/newEmployee';
import allDocs from './authorizerReducers/allDocs'

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    customerReducer,
    SystemDocuments,
    Subscriptions,
    apiReducer,
    userReducer,
    TaskReducer,
    employerReducer,
    scheduleReducer,
    shiftReducer,
    employeeUserReducer,
    payStubsReducer,
    createReviewerReducer,
    dbReducers,
    RewardReducer,
    termsPrivacyReducer,
    couponsReducer,
    newEmployee,
    allDocs
  });
