import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Row from "./row";
import { Grid } from "@material-ui/core";
import {
  getAllCoupons
} from "actions/couponActions";
import { compose } from "recompose";
import { connect } from "react-redux";
import CardBox from "components/CardBox";
import { ScaleLoader } from "react-spinners";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      coupons: []
    };
  }

  componentDidMount() {
    this.props.getAllCoupons();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.getAllCouponsStatus == "done") {
      this.setState({ 
        loader: false,
        coupons: nextProps.coupons
      });
    } else if(nextProps.getAllCouponsStatus == "error") {
      this.setState({ 
        loader: false
      });
    }
  }

  render() {
    const { loader, coupons } = this.state;

    return (
      <div className={styles.root}>
        {loader ? (
          <Grid item align="center">
            <ScaleLoader color={"#123abc"} />
          </Grid>
        ) : (
          <div>
            {coupons.length > 0 ? (
              coupons.map((doc, i) => (
                <Row doc={doc} index={i} />
              ))
            ) : (
              <CardBox styleName="col-lg-12">
                <div style={{textAlign: "center"}}>No, Coupons Found!</div>
              </CardBox>
            )}
          </div>
        )}
      </div>
    );
  }
}

// Coupons.propTypes = {
//   classes: PropTypes.object.isRequired
// };

const mapStateToProps = state => ({
  coupons: state.couponsReducer.coupons,
  getAllCouponsStatus: state.couponsReducer.getAllCouponsStatus,
  loader: state.couponsReducer.loader
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      getAllCoupons
    }
  )
)(Coupons);
