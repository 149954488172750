import React from "react";
import LogInForm from "./components/LoginForm";
import './styles/style.css';

const LogIn = () => (
  // <div className="account">
  //   <div className="account__wrapper">
  //     <div className="account__card">
  //       <div className="account__head">
  //         <h3 className="account__title">
  //           Forget Password
  //           <span className="account__logo">
  //           </span>
  //         </h3>
  //       </div>
  <LogInForm onSubmit />
  //     </div>
  //   </div>
  // </div>
);

export default LogIn;