let url = "https://unohr-server.herokuapp.com";
// let url = "http://localhost:5000";
// "http://localhost:5001/promising-saga-232017/us-central1/adminApi";
export const admin_url =
  "https://us-central1-promising-saga-232017.cloudfunctions.net/adminApi";
export const reviewer_url =
  "https://us-central1-promising-saga-232017.cloudfunctions.net/reviewerApi";

// "http://localhost:5001/promising-saga-232017/us-central1/reviewerApi";
export const payrollApi = {
  create_payPeriod: "/payroll/createPayPeriod",
  change_timeMode: "/payroll/changeTimeMode",
};

export const taskApi = {
  add_task: "/tasks/addTask",
  server_task: "/tasks/serverTask",
};

export const giftApi = {
  get_shopCard: "/gifts/getShopCards",
  send_reward: "/gifts/sendReward",
};

export const subscriptionApi = {
  apply_newSubscription: "/customers/applyNewSubscriptions",
  change_subscription: "/customers/changeSubscriptions",
  cancel_subscription: "/customers/cancelSubscriptions",
};

export const updateApi = "/apikeys/updateApi";
export const addNewReviewerApi = "/reviewer/addNewReviewer";

export default url;
