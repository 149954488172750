import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) {}
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {}

    return null;
  }

  render() {
    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/dashboard/customers"
                >
                  <span className="nav-text">Manage Customers</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/dashboard/subscriptions"
                >
                  <span className="nav-text">Subscriptions</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/dashboard/logs">
                  <span className="nav-text">Reviewer Logs</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="prepend-icon" to="/admin/dashboard/setup">
                  <span className="nav-text">Setup</span>
                </NavLink>
              </li> */}
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void">
              <i className="zmdi zmdi-folder zmdi-hc-fw" />
              <span className="nav-text">Documents</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/documents/new_document"
                >
                  <span className="nav-text">Add Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/documents/manage">
                  <span className="nav-text">Verified Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/documents/unverified_documents"
                >
                  <span className="nav-text">Unverified Documents</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/documents/statedocuments"
                >
                  <span className="nav-text">State Documents</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void">
              <i className="zmdi zmdi-storage" />
              <span className="nav-text">Import DB</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/importdb/wcstate">
                  <span className="nav-text">WC State</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/importdb/minwages">
                  <span className="nav-text">Minimum Wages</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/importdb/newhire">
                  <span className="nav-text">States new hire</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/admin/importdb/districts"
                >
                  <span className="nav-text">Districts</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/importdb/industry">
                  <span className="nav-text">Industry</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="ui_tooltip menu">
            <NavLink to="/admin/discountcoupons">
              <i className="zmdi zmdi-card-giftcard" />
              <span className="nav-text">Discount Coupons</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/admin/tickets">
              <i class="zmdi zmdi-phone" />
              <span className="nav-text">Tickets</span>
            </NavLink>
          </li>

          <li className="ui_tooltip menu">
            <Button className="void">
              <i className="zmdi zmdi-storage" />
              <span className="nav-text">Terms & Privacy</span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/terms/conditions">
                  <span className="nav-text">Terms & Conditions</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/terms/policies">
                  <span className="nav-text">Privacy Policy</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.inBuiltApp" />
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/admin/dashboard/setup">
              <i class="zmdi zmdi-code-setting" />
              <span className="nav-text">Setup API's</span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/admin/shopcards">
              <i class="zmdi zmdi-card-giftcard" />
              <span className="nav-text">Shop Cards</span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink className="prepend-icon" to="/admin/createreviewer">
              <i class="zmdi zmdi-account-add" />
              <span className="nav-text">Add Reviewer / Authorizer</span>
            </NavLink>
          </li>

          {/* <li className="menu no-arrow">
            <NavLink to="/app/to-do">
              <i className="zmdi zmdi-check-square zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.toDo" />
              </span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/contact">
              <i className="zmdi zmdi-account-box zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.contact" />
              </span>
            </NavLink>
          </li> */}

          {/* <li className="menu no-arrow">
            <NavLink to="/app/chat">
              <i className="zmdi zmdi-comment zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.appModule.chat" />
              </span>
            </NavLink>
          </li> */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
