import {
  GET_ALL_KEYS,
  GET_ALL_KEYS_ERR,
  UPDATE_API,
  UPDATE_API_ERR,
  SEND_OTP,
  SEND_OTP_ERR
} from "../../actions/apiAction";

var initialState = {
  loader: "null",
  twilioApi: {},
  sendGridApi: {},
  stripeApi: {},
  getAllApiStatus: "not done",
  apiUpdateStatus: "not done",
  otpSendStatus: "not done"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_KEYS: {
      let data = action.payload;
      let twilioKeys = data.filter(item => item.type == "twilio");
      let sendGridKeys = data.filter(item => item.type == "sendGrid");
      let stripeKeys = data.filter(item => item.type == "stripe");
      return {
        ...state,
        loader: new Date(),
        twilioApi: twilioKeys,
        sendGridApi: sendGridKeys,
        stripeApi: stripeKeys,
        getAllApiStatus: "done"
      };
    }

    case GET_ALL_KEYS_ERR: {
      return {
        ...state,
        loader: new Date(),
        getAllApiStatus: "error"
      };
    }
    case UPDATE_API: {
      let type = action.payload.type;
      console.log("=============type=======================");
      console.log(type);
      console.log("====================================");
      return {
        ...state,
        loader: new Date(),
        apiUpdateStatus: type,
        otpSendStatus: "not done"

      };
    }
    case UPDATE_API_ERR: {
      return {
        ...state,
        loader: new Date(),
        apiUpdateStatus: "error",
        otpSendStatus: "not done"

      };
    }
    case SEND_OTP: {
  
      return {
        ...state,
        loader: new Date(),
        otpSendStatus: "done"
      };
    }
    case SEND_OTP_ERR: {
      return {
        ...state,
        loader: new Date(),
        otpSendStatus: "error"
      };
    }

    default:
      return state;
  }
}
