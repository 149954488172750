import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getAllTickets } from "../../../../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PulseLoader } from "react-spinners";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { changeTicketStatus } from "../../../../actions/userActions";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
});

function createData(ticketId, name, email, date, status) {
  return {
    ticketId,
    name,
    email,
    date,
    status,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [ticketStatus, setticketStatus] = React.useState(row.status);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    console.log(row);
    setticketStatus(event.target.value);
    dispatch(
      changeTicketStatus({ status: event.target.value, docId: row.docId })
    );
  };

  // console.log(ticketStatus);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ticketId}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>
          {moment(
            new Date(row.date.seconds * 1000 + row.date.nanoseconds / 1000000)
          ).format("ll")}
        </TableCell>
        <TableCell>{row.status ? "Opened" : "Closed"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Topic</TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>Detail</TableCell>
                    <TableCell style={{ marginLeft: -10 }}>Attachment</TableCell>
                    <TableCell style={{ marginLeft: -10 }}>Change Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {row.map((historyRow) => ( */}
                  <TableRow key={row.date}>
                    <TableCell component="th" scope="row">
                      {row.topic}
                    </TableCell>
                    <TableCell>{row.subject}</TableCell>
                    <TableCell >{row.detail}</TableCell>
                    {row.attachment ? (
                      <TableCell >
                        <span
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => window.open(row.attachment, "_blank")}
                        >
                          Click to check attachment
                        </span>
                      </TableCell>
                    ) : (
                      <TableCell>Not Attached!</TableCell>
                    )}
                    <TableCell >
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Ticket Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ticketStatus}
                          onChange={handleChange}
                        >
                          <MenuItem value={true}>Open Ticket</MenuItem>
                          <MenuItem value={false}>Close Ticket</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable(props) {
  const dispatch = useDispatch();
  const allTickets = useSelector((state) => state.userReducer.allTickets);
  const status = useSelector((state) => state.userReducer.ticketsStatus);
  const [checked, setChecked] = React.useState(true);

  const handleChangeSwitch = (panel) => (event, isExpanded) => {
    console.log(
      "===>panel",
      panel,
      "====>isExpanded",
      isExpanded,
      "====>",
      event
    );
    setChecked(isExpanded ? panel : false);
  };

  allTickets.sort(function (a, b) {
    return b.date - a.date;
  });

  var filterForTickets = allTickets;

  let searchStringForTickets = props.searchQuery.trim().toLowerCase();
  if (searchStringForTickets.length > 0) {
    filterForTickets = filterForTickets.filter(function (item) {
      return (item.ticketId.toLowerCase().match(searchStringForTickets) 
      || item.name.toLowerCase().match(searchStringForTickets) )
    });
  }

  const rows = filterForTickets.map((i) => {
    return createData(i.ticketId, i.name, i.email, i.date, i.status);
  });

  useEffect(() => {
    dispatch(getAllTickets());
  }, []);

  return status ? (
    <div style={{ width: "100%", textAlign: "center" }}>
      <PulseLoader />
    </div>
  ) : filterForTickets.length ? (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Ticket ID</TableCell>
            <TableCell>Employer Name</TableCell>
            <TableCell>Employer Email</TableCell>
            <TableCell>Ticket Submit Date</TableCell>
            <TableCell>Ticket Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterForTickets.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div style={{ textAlign: "center", fontSize: 20, width: "100%" }}>
      No ticket found
    </div>
  );
}
