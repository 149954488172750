import { db } from "../firebase/firebase";
import { admin_url, updateApi } from "../Endpoint";
import toastr from "toastr";
import axios from "axios";
export const GET_ALL_KEYS = "GET_ALL_KEYS";
export const GET_ALL_KEYS_ERR = "GET_ALL_KEYS_ERR";
export const UPDATE_API = "UPDATE_API";
export const UPDATE_API_ERR = "UPDATE_API_ERR";
export const GET_API_FUNC = "GET_API_FUNC";
export const GET_API_FUNC_ERR = "GET_API_FUNC_ERR";
export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_ERR = "SEND_OTP_ERR";

export function getAllKeys() {
  return (dispatch) => {
    db.collection("api").onSnapshot(
      function (querySnapshot) {
        let datatoStore = [];
        querySnapshot.forEach(function (doc) {
          const id = doc.id;
          const data = doc.data();
          datatoStore.push({ id, ...data });
          return datatoStore;
        });

        dispatch({
          type: GET_ALL_KEYS,
          payload: datatoStore,
        });
      },
      function (err) {
        dispatch({
          type: GET_ALL_KEYS_ERR,
        });
      }
    );
  };
}
// for update api

export const updateApis = (data) => {
  console.log("===========data in action=========================");
  console.log(data);
  console.log("====================================");
  // "https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/updateApi",
  return (dispatch) => {
    axios
      .post(`${admin_url}${updateApi}`, data)
      .then((res) => {
        if (res.data == "successfully updated") {
          console.log("res", res.data);
          toastr.success("Succefully updated");
          dispatch({
            type: UPDATE_API,
            payload: data,
          });
        } else {
          console.log("else", res.data);
          toastr.error("1 Error occured. Please try again later");
          dispatch({
            type: UPDATE_API_ERR,
          });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error("err", err);
        toastr.error("2 Error occured. Please try again later");
        dispatch({
          type: UPDATE_API_ERR,
        });
      });
  };
};

// verify number
export function verifyNumber(data) {
  return (dispatch) => {
    axios
      .post(
        " https://us-central1-promising-saga-232017.cloudfunctions.net/superAdminApi/sendmessage",
        data
      )
      .then((res) => {
        console.log("res", res.data);
        if (res.data == "successfully sent") {
          console.log("res", res.data);
          dispatch({
            type: SEND_OTP,
          });
        } else {
          console.log("else");

          dispatch({ type: SEND_OTP_ERR });
        }
        //err
      })
      .catch((err) => {
        //err
        console.error("err", err);
        dispatch({ type: SEND_OTP_ERR });
      });
  };
}
