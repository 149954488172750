import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Input } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class ContainerHeader extends Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      title,
      match,
      onTextChangeHandler,
      onDateChangeHandler,
      searchQuery,
      searchDateQuery,
      placeholder,
      dateInput,
      searchInput,
      filter,
      stateDocs,
      systemDocs,
      filterAction
    } = this.props;

    return (
      <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center" >
        <h2 className="title mb-3 mb-sm-0" style={{ display: "flex", alignItems: "center" }}>{title}</h2>
        <div>
          {searchInput ? (
            <div />
          ) : (
            <Input
              value={searchQuery}
              onChange={onTextChangeHandler}
              placeholder={placeholder ? placeholder : "Search document"}
              style={{ paddingLeft: 5, color: "blue" }}
            />
          )}
          {dateInput && (
            <Input
              value={searchDateQuery}
              onChange={onDateChangeHandler}
              placeholder={dateInput}
              style={{ paddingLeft: 5, color: "blue" }}
            />
          )}
          {/* {this.props.logButton != null && this.props.logButton
        : (
          <Breadcrumb className="mb-0" tag="nav">
            {subPath.map((sub, index) => {
              return (
                <BreadcrumbItem
                  active={subPath.length === index + 1}
                  tag={subPath.length === index + 1 ? "span" : "a"}
                  key={index}
                  href={getUrlString(path, sub, index)}
                >
                  {getDisplayString(sub)}
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )
        } */}

          {filter && (
            <>
              <IconButton onClick={this.handleMenu}>
                <i class="zmdi zmdi-filter-list filterIcon"></i>
              </IconButton>
              <Menu
                id="menu-list-grow"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={systemDocs}
                        onChange={filterAction("systemDocs")}
                        value="System Document"
                        color="primary"
                      />
                    }
                    label="System Documents"
                  />
                </MenuItem>
                <MenuItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stateDocs}
                        onChange={filterAction("stateDocs")}
                        value="State Document"
                        color="primary"
                      />
                    }
                    label="State Documents"
                  />
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ContainerHeader;
