export default [
  {
    'id': 0,
    'handle': 'starred',
    'title': 'Starred',
    'icon': 'star'
  },
  {
    'id': 1,
    'handle': 'important',
    'title': 'Important',
    'icon': 'label'
  }
];
