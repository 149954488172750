import React, { Component } from "react";
import ListItem from "./Component/ListItem";
import ContainerHeader from "components/ContainerHeader";
import { connect } from "react-redux";
import { RingLoader } from "react-spinners";
import TablePaginationActionsWrapped from "components/pagination/TablePagination";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Hidden from "@material-ui/core/Hidden";
import moment from "moment";
import { getNewEmployee } from "../../../../actions/authorizerAction/newEmployees";

class StripList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: true,
      employees: [],
      noContentFoundMessage: "No New Employee Found",
      page: 0,
      rowsPerPage: 5,
      searchQuery: "",
      getEmpReq: true
    };
  }
  componentDidMount() {
    if (this.state.getEmpReq) {
      this.props.getNewEmployee(this.getEmpRes);
    }
  }

  getEmpRes = type => {
    if (type === "success") {
      this.setState({
        loader: false,
        getEmpReq: false
      });
    } else {
      this.setState({
        loader: false,
        getEmpReq: true
      });
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onTextChangeHandler = event => {
    this.setState({
      searchQuery: event.target.value
    });
  };

  searchingForName = searchQuery => {
    return function(data) {
      return (
        data.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        moment(data.chosenDate)
          .format("MMM/DD/YYYY")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        data.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        !searchQuery
      );
    };
  };

  render() {
    const { newEmployees } = this.props;
    const {
      loader,
      contactList,
      noContentFoundMessage,
      rowsPerPage,
      page
    } = this.state;
    return (
      <div className="stateScheduleCon">
        <ContainerHeader
          title="New Employees' Documents"
          searchQuery={this.state.searchQuery}
          onTextChangeHandler={this.onTextChangeHandler}
        />
        <div className="animated slideInUpTiny animation-duration-3">
          {loader ? (
            <div style={{ marginTop: "25px", marginLeft: "40%" }}>
              <RingLoader size={60} color={"#123abc"} />
            </div>
          ) : newEmployees.length === 0 ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              {noContentFoundMessage}
            </div>
          ) : (
            newEmployees
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .filter(this.searchingForName(this.state.searchQuery))
              .map((data, index) => (
                <ListItem key={index} data={data} styleName="card-strip" />
              ))
          )}
          {newEmployees.length > 5 && (
            <div className="row">
              <div
                className="col-md-12"
                style={{ backgroundColor: "white", margin: "15px 0px" }}
              >
                <Hidden only={["xs", "sm"]}>
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[3, 5, 10, 20, 50, 100]}
                          labelRowsPerPage="Customers per page"
                          colSpan={7}
                          count={contactList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions=""
                          labelRowsPerPage=""
                          colSpan={7}
                          count={contactList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Hidden>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    newEmployees: state.newEmployee.newEmployees
  };
};

export default connect(mapStateToProps, { getNewEmployee })(StripList);
