import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import { Grid, Button } from "@material-ui/core";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { ScaleLoader, BeatLoader } from "react-spinners";
import PdfForm from "./pdfForm";
class Expansion extends Component {
  state = {
    expand: false,
    expandType: ""
  };

  handleExpansion = (e, expandType) => {
    e.stopPropagation();
    this.setState({
      expandType,
      expand: true
    });
  };

  handleClose = () => {
    this.setState({
      expandType: "",
      expand: false
    });
  };
  render() {
    const { doc, index, classes } = this.props;
    const { expand, expandType } = this.state;
    return (
      <ExpansionPanel key={index} expanded={expand}>
        <ExpansionPanelSummary onClick={this.handleClose}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <div className="docName">
                <Typography className={classes.Number}>{index + 1}.</Typography>
                <Typography className={classes.heading}>
                  {doc.doc_name}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.secondaryHeading}>
                {doc.state}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.secondaryHeading}>
                Modified: {moment(doc.updatedAt).format("ll")}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <div className="actionDocAuth">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={e => this.handleExpansion(e, "generate")}
                >
                  Generate PDF
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={e => this.handleExpansion(e, "request")}
                >
                  Change Request
                </Button>
              </div>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {expandType === "generate" ? (
            <PdfForm
              doc_id={doc.doc_id}
              doc_name={doc.doc_name}
              template_id={doc.template_id}
              docType={doc.docType}
            />
          ) : (
            <div className="changeReqTextArea">
              <div className="form-group">
                <label>Detail for changes in this documents</label>
                <textarea className="form-control form-control-lg" rows="6" />
              </div>

              <div className="form-group mb-0">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default Expansion;
