import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyDE0OZEif3XbEIc9_X09D5b9qHxkd1CagQ",
  authDomain: "promising-saga-232017.firebaseapp.com",
  databaseURL: "https://promising-saga-232017.firebaseio.com",
  projectId: "promising-saga-232017",
  storageBucket: "promising-saga-232017.appspot.com",
  messagingSenderId: "577836332495",
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();
const db = firebase.firestore();
const storage = firebase.storage();
export {
  auth,
  database,
  db,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  storage,
};
