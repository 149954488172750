import React, { Component, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import AllDocuments from "./components/index";
import {
  getStateDocs,
  updateStateDoc,
  deleteStateDoc,
} from "../../../../../../actions/NewSystemDocuments";
import { useObjectState } from "../../../../../../util/commonState";
import { ScaleLoader } from "react-spinners";

function BasicTables() {
  const [state, setState] = useObjectState({
    loader: true,
    error: false,
    errorMsg: "",
    allDocs: [],
  });

  const stateVerifiedDocs = useSelector(
    (state) => state.SystemDocuments.stateVerifiedDocs
  );
  // console.log(stateVerifiedDocs);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStateDocs(getRes));
  });
  const getRes = (type, error) => {
    console.log("called", type, stateVerifiedDocs);
    if (type === "success") {
      let allDocs = [];
      stateVerifiedDocs.forEach((element) => {
        // console.log(element, "==element");
        let doc = {
          schedule: "Schedule in months",
          state: "Set State",
          sendingPeriod: "Period in days",
          ...element,
        };
        allDocs.push(doc);
      });
      setState({
        loader: false,
        error: false,
        allDocs,
      });
    } else {
      setState({
        loader: false,
        error: true,
        errorMsg: error,
      });
    }
  };
  const updateDoc = (payload) => {
    dispatch(updateStateDoc(payload));
  };

  const { loader, error, errorMsg, allDocs } = state;
  return (
    <Container>
      {loader ? (
        <Row className="docsPending">
          <ScaleLoader color={"#123abc"} />
        </Row>
      ) : error ? (
        <Row className="docsPending">
          <p>{errorMsg}</p>
        </Row>
      ) : (
        <Row className="docsPending">
          <AllDocuments
            stateVerifiedDocs={allDocs}
            updateDoc={updateDoc}
            deleteStateDoc={deleteStateDoc}
          />
        </Row>
      )}
    </Container>
  );
}

export default BasicTables;
